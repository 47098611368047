import { routerComponents } from './routerComponents';
// import combinedAliases from './routerAliases';

// Order: /static, /index, /:dynamic
// Match exact route before index: /login before /index/_slug
const routes = [
  {
    name: 'register',
    path: '/register',
    component: routerComponents.Landing,
  },
  {
    name: 'login',
    path: '/login',
    component: routerComponents.Landing,
  },
  {
    name: 'main',
    path: '/',
    //alias: combinedAliases.category.map(alias => `/${alias}`),
    component: routerComponents.Recipes,
  },
  {
    path: '/recipes/:id/:slug?',
    alias: '/retsept/:id/:slug?',
    component: routerComponents.RecipeBase,
    children: [
      {
        name: 'recipe',
        path: '',
        component: routerComponents.Recipe,
      },
    ],
  },
  { name: 'notFound', path: '/page-not-found', component: routerComponents.NotFound },
  { name: 'embed', path: '/embed', component: routerComponents.Embed },
];

export default routes;
