
import Vue from 'vue';
import getSubscription from '~/core/utils/getSubscription';
import RecipeButton from '~/components/UI/UserProfile/shared/RecipeButton.vue';
import PianoCustomerStateHandler from '../../../libraries/piano/src/components/PianoCustomerStateHandler.vue';

interface Props {
  displayName: string;
  hasAccess: boolean;
}

interface Methods {
  goToProfile: () => void;
  getSubscription: () => void;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    PianoCustomerStateHandler,
    RecipeButton,
  },
  props: {
    displayName: String,
    hasAccess: Boolean,
  },
  methods: {
    getSubscription: () => getSubscription(),
    goToProfile() {
      window?.open('https://www.delfi.ee/klient/konto', '_blank');
    },
  },
});
