
import Vue from 'vue';

interface NotificationContent {
  icon: string;
  prefix: string;
  suffix: string;
  bold: boolean;
  quotations?: boolean;
}

type NotificationTypeContent = Record<string, NotificationContent>;

interface Data {
  notificationTypeContent: NotificationTypeContent;
}

interface Props {
  type: string;
  title: string;
}

interface Methods {
  notificationIcon: string;
}

export default Vue.extend<Data, unknown, Methods, Props>({
  data() {
    return {
      notificationTypeContent: {
        error: { icon: 'alert', prefix: '', suffix: '', bold: false },
        success: { icon: 'success', prefix: '', suffix: '', bold: false },
        'success-ui': { icon: 'success', prefix: 'Lisatud ostukorvi: ', suffix: '', bold: true },
        favourite: { icon: 'heart-active', prefix: 'Lisatud kausta: ', suffix: '!', bold: true },
        folder: { icon: 'created-folder', prefix: 'Uus kaust ', suffix: ' loodud!', bold: true, quotations: true },
        'folder-erased': { icon: 'folder-erased', prefix: 'Kaust ', suffix: ' kustutatud', bold: true, quotations: true },
        unfavourite: { icon: 'unfavourite', prefix: 'Retsept eemaldatud kaustast: ', suffix: '', bold: false },
      },
    };
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
});
