import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { Recipe, RecipeBasket, Tag, TagGroup, ClientTypes, CustomerDataObject, ModifiedRecipe, PaymentMethods, GetCustomerDataInput } from '../../types';
import { ServiceConfig } from '~/core/modules/service-config/typings/ServiceConfig';
import getCustomerData from '../modules/services/getCustomerData';
import getRecipes from '../modules/services/getRecipes';
import filterDublicates from '../utils/filterDublicates';
import { RootState } from './store-types';
import groupBy from '../utils/groupBy';
import getSearchTags from '../utils/getSearchTags';
import formatRecipes from '../utils/formatRecipes';
import createCustomerData from '../modules/services/createCustomerData';
import updateCustomerData from '../modules/services/updateCustomerData';
import deleteCustomerDataBatch from '../modules/services/deleteCustomerDataBatch';

export interface State {
  serviceConfig: ServiceConfig | null;
  searchResults: ModifiedRecipe[];
  savedResults: RecipeBasket[];
  isModalOpen: Record<string, boolean | null>;
  searchTags: Tag[];
  tagGroups: TagGroup[];
  selectedTags: string[];
  hasAccess: boolean;
  customerFolders: CustomerDataObject[];
  selectedUserFolder: string | null;
  searchTerm: string | null;
  favouriteRecipesIds: string[];
  folderRecipes: Record<string, string>;
  isError: boolean;
  totalNumberOfRecipesAvailable: number;
  modalsHistory: any[];
  activeRecipe: ModifiedRecipe | null;
  isPortalOpened: boolean;
  paymentMethods: PaymentMethods | null;
}

const initialModalsState = {
  left: false,
  center: false,
  centerType: null,
  right: false,
  id: null,
  extraInfo: null,
};

export const state: () => State = () => ({
  isModalOpen: initialModalsState,
  savedResults: [],
  searchResults: [],
  searchTags: [],
  tagGroups: [],
  selectedTags: [],
  customerFolders: [],
  selectedUserFolder: null,
  searchTerm: null,
  favouriteRecipesIds: [],
  folderRecipes: {},
  isError: false,
  totalNumberOfRecipesAvailable: 0,
  modalsHistory: [],
  activeRecipe: null,
  isPortalOpened: false,
  hasAccess: false,
  paymentMethods: null,
  serviceConfig: null,
});

export const getters: GetterTree<State, RootState> = {
  serviceConfig: (state) => {
    return state.serviceConfig;
  },
  isModalOpen(state) {
    return state.isModalOpen;
  },
  hasAccess(_state, _root, rootState) {
    // @ts-ignore
    return rootState.piano.access.channelAccess;
  },
  isPortalOpened(state) {
    return state.isPortalOpened;
  },
  searchResults(state) {
    return state.searchResults;
  },
  savedResults(state) {
    return state.savedResults;
  },
  searchTags(state) {
    return state.searchTags;
  },
  tagGroups(state) {
    return state.tagGroups;
  },
  selectedTags(state) {
    return state.selectedTags;
  },
  customerFolders(state) {
    return state.customerFolders;
  },
  customer(_state, _root, rootState) {
    return rootState.piano;
  },
  token(_state, _root, rootState) {
    return rootState.piano.token;
  },
  prevRoute(_state, _root, rootState) {
    return rootState.route.from.name;
  },
  activeUserFolder(state) {
    return state.selectedUserFolder;
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  favouriteRecipesIds(state) {
    return Object.values(state.folderRecipes).reduce((acc: any[], folder: any) => [...acc, ...folder], []);
  },
  folderRecipes(state) {
    return state.folderRecipes;
  },
  isError(state) {
    return state.isError;
  },
  totalNumberOfRecipesAvailable(state) {
    return state.totalNumberOfRecipesAvailable;
  },
  modalsHistory(state) {
    return state.modalsHistory;
  },
  activeRecipe(state) {
    return state.activeRecipe;
  },
  paymentMethods(state) {
    return state.paymentMethods;
  },
};

export const mutations: MutationTree<RootState> = {
  serviceConfig(state, config: State['serviceConfig']) {
    state.serviceConfig = config;
  },
  toggleModal(state, { modal, centerType, id }) {
    const newModal = { ...initialModalsState, [modal]: !state.isModalOpen[modal], centerType, id };
    // check if centerType not null -> not closing
    if (centerType) state.modalsHistory = [...state.modalsHistory, newModal];
    state.isModalOpen = newModal;
  },
  toggleModalNoCloseOtherModals(state, { modal, centerType, id }) {
    state.isModalOpen = { ...state.isModalOpen, [modal]: !state.isModalOpen[modal], centerType, id };
  },
  pushModalHistory(state, { centerType, id = null, extraInfo = null }) {
    state.modalsHistory = [...state.modalsHistory, { ...state.isModalOpen, centerType, id, extraInfo }];
  },
  changeModalCenterType(state, { centerType, id, extraInfo = null }) {
    const newModal = { ...state.isModalOpen, centerType, id, extraInfo };
    state.modalsHistory = [...state.modalsHistory, newModal];
    state.isModalOpen = newModal;
  },
  popModalsHistory(state) {
    const copyArray = [...state.modalsHistory];
    // remove last item from history
    copyArray.pop();
    // append modified state
    state.modalsHistory = [...copyArray];
    // check if history is not empty by selecting last item
    const historyLast = [...copyArray].pop();
    // if history not empty then change modal state
    if (historyLast) state.isModalOpen = historyLast;
  },
  setSearchResults(state, { items, pager }) {
    state.searchResults = formatRecipes(items);
    state.totalNumberOfRecipesAvailable = pager.count;
  },
  addToSearchResults(state, { items }) {
    const filteredSearchResults = filterDublicates(items, 'id') as Recipe[];
    state.searchResults = [...state.searchResults, ...formatRecipes(filteredSearchResults)];
  },
  setSavedResults(state, { recipe, setAll = false }) {
    if (setAll) {
      state.savedResults = [...recipe];
    } else {
      const savedResultsCopy = [...state.savedResults];
      const existingRecipe = savedResultsCopy.find((r) => r.id === recipe.id);
      existingRecipe ? (existingRecipe.portions += recipe.portions) : savedResultsCopy.push(recipe);
      state.savedResults = savedResultsCopy;
      localStorage.setItem('savedRecipes', JSON.stringify(savedResultsCopy));
    }
  },
  removeFromSavedResults(state, newSavedResults) {
    state.savedResults = newSavedResults;
    localStorage.setItem('savedRecipes', JSON.stringify(newSavedResults));
  },
  setSearchTags(state, tags) {
    const searchTags = getSearchTags(state.searchTags, tags);
    state.searchTags = searchTags;
  },
  setTagGroups(state, { items }) {
    state.tagGroups = filterDublicates(items, 'id');
  },
  setSelectedTags(state, tags) {
    const searchTags = getSearchTags(state.searchTags, tags);
    state.searchTags = searchTags;
  },
  setHasAccess(state, bool) {
    state.hasAccess = bool;
  },
  setIsPortalOpened(state, bool) {
    state.isPortalOpened = bool;
  },
  setUserFolders(state, customerFolders) {
    state.customerFolders = customerFolders;
  },
  setActiveUserFolder(state, folderId) {
    const selectedUserFolder = state.selectedUserFolder;
    const isSameFolderId = folderId === selectedUserFolder;
    state.selectedUserFolder = isSameFolderId ? null : folderId;
  },
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },
  setFolderRecipes(state, recipes) {
    state.folderRecipes = recipes;
  },
  setError(state, error) {
    state.isError = error;
  },
  emptySearchTags(state) {
    state.searchTags = [];
  },
  setActiveRecipe(state, recipe) {
    state.activeRecipe = recipe;
  },
  resetModals(state) {
    state.isModalOpen = initialModalsState;
  },
  setPaymentMethods(state, methods) {
    state.paymentMethods = methods;
  },
};

export const actions: ActionTree<RootState, Record<string, any>> = {
  async addCustomerData({ dispatch, getters }, { key, value }) {
    try {
      const { data } = await createCustomerData({ client: ClientTypes.customer, authToken: getters.token }, { input: { key, value } });
      if (data.status && data.customerData) {
        await dispatch('refreshCustomerFolders');
        return data.customerData.id;
      }
    } catch (e: any) {
      this.app.$sentry.captureException(`addCustomerData error: ${e.message}`);
      return null;
    }
  },
  async updateCustomerData({ dispatch, getters }, { id, value }) {
    try {
      const { data } = await updateCustomerData({ client: ClientTypes.customer, authToken: getters.token }, { input: { id, value } });
      if (data.status) {
        await dispatch('refreshCustomerFolders');
      }
    } catch (e: any) {
      this.app.$sentry.captureException(`updateCustomerData error: ${e.message}`);
    }
  },
  async deleteCustomerData({ dispatch, getters }, { key, value }) {
    try {
      const { data } = await deleteCustomerDataBatch({ client: ClientTypes.customer, authToken: getters.token }, { input: { key, value } });
      if (data.status) {
        await dispatch('refreshCustomerFolders');
      }
    } catch (e: any) {
      this.app.$sentry.captureException(`deleteCustomerData error: ${e.message}`);
    }
  },
  async refreshCustomerFolders({ commit, getters }) {
    const auth = { client: ClientTypes.customer, authToken: getters.token };
    const input: GetCustomerDataInput['input'] = { key: 'recipe-web.folder' };

    try {
      const data = auth && auth.authToken && (await getCustomerData(auth, { input }));
      if (data) {
        commit('setUserFolders', data.items);
        const folderIds = data.items && data.items.map((folder: CustomerDataObject) => `recipe-web.folder.${folder.id}`);
        if (folderIds) {
          const favouritesFromFolders = await getCustomerData(auth, { input: { key: folderIds } }).then((r) => r?.items);
          favouritesFromFolders && commit('setFolderRecipes', groupBy(favouritesFromFolders, 'key', 'value'));
        }
      }
    } catch (e: any) {
      this.app.$sentry.captureException(`refreshCustomerFolders error: ${e.message}`, { contexts: { errorData: { e }, data: input } });
    }
  },
  async getRecipesWithSearch({ commit, getters, rootState }, additionalSearch) {
    const hasAccess = getters.hasAccess;
    const routeQuery = rootState.route.query;
    let input: Record<string, any> = { limit: 20, fetchPreparation: hasAccess };
    // check for search term
    if (routeQuery.search) {
      const search = { search: { field: ['title', 'ingredient.title'], value: routeQuery.search } };
      input = { ...input, ...search };
    }
    // check for active filter tags
    if (routeQuery.tags?.length) {
      const tagsSearch = { tagId: routeQuery.tags };
      input = { ...input, ...tagsSearch };
    }
    // check for active folder selected
    const activeFolder = getters.activeUserFolder;
    if (activeFolder) {
      const folderFavouriteRecipes = getters.folderRecipes[`recipe-web.folder.${activeFolder}`] || [];
      const variables = { id: folderFavouriteRecipes };
      input = { ...input, ...variables };
    }
    // additional input filter
    if (additionalSearch) {
      input = { ...input, ...additionalSearch };
    }

    try {
      const result = await getRecipes({ ...input });

      if (process.client) {
        const mutation = input.offset > 0 ? 'addToSearchResults' : 'setSearchResults';
        commit(mutation, result.data);
      }

      return process.client ? null : result.data;
    } catch (e: any) {
      this.app.$sentry.captureException(`getRecipesWithSearch error: ${e.message}`, { contexts: { errorData: { e }, data: input } });
      return null;
    }
  },
  clearAllFilters({ commit }) {
    commit('setSearchTerm', null);
    commit('emptySearchTags');
    commit('setActiveUserFolder', null);
    commit('setSelectedTags', []);
  },
  afterLogout({ commit }) {
    localStorage && localStorage.removeItem('savedRecipes');
    commit('resetModals');
  },
};
