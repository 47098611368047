
import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserProfileHeader from '../shared/UserProfileHeader.vue';
import CheckBox from '../shared/CheckBox.vue';
import LoadMoreLess from '../shared/LoadMoreLess.vue';
import { Tag } from '~/types';

interface Data {
  limit: number;
}

interface Methods {
  isChecked: (id: string) => boolean;
  select: (tag: Tag) => void;
  showMore: () => void;
  resetLimit: () => void;
}

interface Props {
  title: string;
  items: Tag[];
  isMobile: boolean;
}

interface Computed {
  searchTags: Tag[];
  isLoadMoreButtonVisible: boolean;
  itemsToShow: Tag[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    UserProfileHeader,
    CheckBox,
    LoadMoreLess,
  },
  props: {
    title: String,
    items: Array,
    isMobile: Boolean,
  },
  data() {
    return {
      limit: 4,
    };
  },
  computed: {
    ...mapGetters(['searchTags']),
    isLoadMoreButtonVisible() {
      return this.items.length > this.limit;
    },
    itemsToShow() {
      return this.items.slice(0, this.limit);
    },
  },
  methods: {
    isChecked(id) {
      return this.searchTags.find((s: Tag) => s.id === id) !== undefined;
    },
    select(tag) {
      this.$store.commit('setSelectedTags', [tag]);
      this.$store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'recipe_filter_change',
          eventData: {
            tag_group: this.title.toLowerCase(),
            tag_name: tag.title.toLowerCase(),
            tag_status: this.isChecked(tag.id) ? 'on' : 'off',
          },
        },
      });
    },
    showMore() {
      this.limit = this.items.length;
    },
    resetLimit() {
      this.limit = 4;
    },
  },
});
