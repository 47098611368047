
import Vue from 'vue';

interface Methods {
  redirect: (url: string) => void;
}

interface Computed {
  year: number;
}

export default Vue.extend<unknown, Methods, Computed, unknown>({
  methods: {
    redirect(url: string) {
      window?.open(url, '_blank');
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
});
