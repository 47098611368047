var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-container"},[_c('transition',{attrs:{"tag":"div","name":"opacity"}},[(_vm.show && !_vm.isStatic)?_c('div',{staticClass:"modal-container__backdrop",class:{
        'modal-container__backdrop--center': _vm.side === 'center',
        'modal-container__backdrop--left': _vm.side === 'left',
        'modal-container__backdrop--right': _vm.side === 'right',
        'modal-container__backdrop--static': _vm.isStatic,
      },on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"tag":"div","name":_vm.animation}},[(_vm.show)?_c('div',{staticClass:"modal-container__body",class:{
        'modal-container__body--center': _vm.side === 'center',
        'modal-container__body--left': _vm.side === 'left',
        'modal-container__body--right': _vm.side === 'right',
        'modal-container__body--static': _vm.isStatic,
        'modal-container__body--extra-height': _vm.isAutoHeight,
      }},[_vm._t("default")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }