import { ModifiedRecipe, Recipe } from '~/types';

const urlParams = (cropperData: Record<string, any>) =>
  Object.keys(cropperData).reduce((acc: string, item: string) => {
    let urlParam = '';
    const cropperItem = cropperData[item];
    switch (item) {
      case 'flip':
        urlParam = cropperItem && `flip=${cropperItem}`;
        break;
      case 'rotate':
        urlParam = cropperItem && `rotate=${cropperItem}`;
        break;
      case 'focuspoint':
        urlParam = cropperItem && `fx=${cropperItem.x}&fy=${cropperItem.y}`;
        break;
      case 'ratio':
        urlParam = cropperItem && cropperItem !== 'Original' ? `r=${cropperItem}` : '';
        break;
      case 'customCrops':
        // eslint-disable-next-line no-case-declarations
        const findCustomCrop = cropperItem.find((i: Record<string, any>) => i.ratio === cropperData.ratio);
        urlParam = findCustomCrop ? `cx=${findCustomCrop.cx}&cy=${findCustomCrop.cy}&cw=${findCustomCrop.cw}&ch=${findCustomCrop.ch}` : '';
        break;
      default:
        break;
    }
    return urlParam ? acc.concat(`&${urlParam}`) : acc;
  }, '');

export default (recipes: Recipe[]): ModifiedRecipe[] =>
  recipes.map((recipe: Recipe) => {
    const maxWidth = 460;
    const maxHeight = 350;
    const { id: photoId, type: photoType, cropperData: photoCropperData, metadata } = JSON.parse(JSON.stringify(recipe.photo));
    // const photoRatio = photoCropperData && photoCropperData.ratio ? `&r=${encodeURIComponent(photoCropperData.ratio)}` : '';
    const photoAuthor = metadata && metadata.authors ? metadata.authors.join(', ') : '';
    const maxWidthAndHeight = `w=${maxWidth}&h=${maxHeight}`;
    const cropperUrl = process.env.MEDIA_API_CROPPER || process.env.VUE_APP_MEDIA_API_CROPPER;
    const photo = photoType === 'IMAGE' ? `${cropperUrl}/${photoId}.jpg?${maxWidthAndHeight}${urlParams(photoCropperData)}` : '';
    const metaPhoto = photoType === 'IMAGE' ? `${cropperUrl}/${photoId}.jpg?w=1200&h=800${urlParams(photoCropperData)}` : '';
    let preparationPhoto = '';

    const prep = JSON.parse(JSON.stringify(recipe.preparationPhoto));
    if (prep) {
      const { id: prepId, type: prepType, cropperData: prepCropperData } = prep;
      // const preparationPhotoRatio = prepCropperData && prepCropperData.ratio ? `?r=${encodeURIComponent(prepCropperData.ratio)}` : '';
      preparationPhoto = prepType === 'IMAGE' ? `${cropperUrl}/${prepId}.jpg?${maxWidthAndHeight}${urlParams(prepCropperData)}` : '';
    }

    return { ...recipe, photo, metaPhoto, preparationPhoto, photoAuthor };
  });
