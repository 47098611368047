import { PureQueryOptions } from '@apollo/client/core';
import fetchData from '../../apollo/resolvers/fetchData';
import { GetRecipes } from '../../apollo/typings/getRecipes';
import getRecipes from '../../apollo/query/getRecipes.graphql';
import { ClientTypes, GetRecipesInput, OrderDirection } from '~/types';
import RedisClient from '../../redis/redisClient';

const error = {
  message: 'Recipes not found.',
  statusCode: 404,
};

interface Variables {
  id?: GetRecipesInput['id'];
  externalId?: GetRecipesInput['externalId'];
  search?: GetRecipesInput['search'];
  tagId?: GetRecipesInput['tagId'];
  fetchPreparation?: GetRecipesInput['fetchPreparation'];
  limit?: GetRecipesInput['pager']['limit'];
  offset?: GetRecipesInput['pager']['offset'];
  orderBy?: GetRecipesInput['pager']['orderBy'];
  orderDirection?: GetRecipesInput['pager']['orderDirection'];
}

interface QueryOptions {
  variables: {
    fetchPreparation?: GetRecipesInput['fetchPreparation'];
    input: Input;
  };
}

interface Input {
  id?: GetRecipesInput['id'];
  externalId?: GetRecipesInput['externalId'];
  tagId?: GetRecipesInput['tagId'];
  search?: GetRecipesInput['search'];
  pager?: GetRecipesInput['pager'];
}

function getQueryOptions({ search, limit, tagId, orderBy, orderDirection, id, externalId, offset, fetchPreparation }: Variables) {
  const queryOptions: QueryOptions = {
    variables: {
      fetchPreparation,
      input: {
        search,
        tagId,
        id,
        externalId,
        pager: {
          limit,
          orderDirection,
          orderBy,
          offset,
        },
      },
    },
  };

  return queryOptions;
}

const fetchRecipes = async (variables?: Variables) => {
  const queryOptions = variables && getQueryOptions({ ...variables, orderBy: 'created_at', orderDirection: OrderDirection.DESC });
  const options: PureQueryOptions = Object.assign({ query: getRecipes, cacheData: false }, queryOptions);
  try {
    // If we have a cached entry send it
    const key = `fetchRecipes::${JSON.stringify(variables)}`;
    const dataCache = await RedisClient.get(key);

    if (dataCache) {
      return JSON.parse(dataCache);
    }

    const recipes = await fetchData<GetRecipes['data']>({ client: ClientTypes.recipes }, options);

    if (!recipes) {
      throw 'fetchRecipes request is empty';
    }

    await RedisClient.set(key, recipes, 60 * 15);
    return recipes;
  } catch (e) {
    throw error;
  }
};

export default fetchRecipes;
