import { Plugin } from '@nuxt/types';
import { GetSiteControl } from '@analytics/trackers';
import config from '~/core/config/analytics.config';

type Rating = Record<string, boolean>;

interface BaseParams {
  segmentIds: string;
  profile: string;
  site: string;
}
const GetSiteControlPlugin: Plugin = async (ctx) => {
  const { store } = ctx;

  const baseParams: BaseParams = {
    segmentIds: '',
    profile: '',
    site: 'retseptiveeb.delfi.ee',
  };

  if (!config.getSiteControl?.url) {
    return;
  }

  let gsc: GetSiteControl | null = null;

  try {
    gsc = new GetSiteControl();
    await gsc.init({ url: config.getSiteControl.url });
  } catch (e) {
    console.error('Failed to load GSC', e);
    return;
  }

  const sendCxenseEvent = (widgetId: number, data: { rating: Rating }) => {
    const { rating }: { rating: Rating } = data;
    store.commit('analytics/setClickEvent', {
      cXense: {
        eventName: 'QUESTIONNAIRE_SUBMIT',
        eventData: {
          type: 'submit',
          data: {
            widgetId,
            rating: Object.keys(rating).join(),
          },
        },
      },
    });
  };

  gsc.event('onSubmit', (widgetId, data) => sendCxenseEvent(widgetId, data));

  const setGscParams = (baseParams: BaseParams) => {
    baseParams.profile = store.state.piano.subscriptionInfo ? store.state.piano.subscriptionInfo.customerIdHash : '';

    // @ts-ignore
    gsc!.params(baseParams);
  };

  const handleNewClickEvent = (clickEventName: any) => {
    if (!clickEventName) {
      return;
    }

    if (!store.state.analytics.tracker.getSiteControl.clickEventData) {
      return false;
    }

    const { data } = store.state.analytics.tracker.getSiteControl.clickEventData ?? {};
    baseParams.segmentIds = data.segmentIds ?? baseParams.segmentIds;
    baseParams.profile = data.profile ?? baseParams.profile;

    setGscParams(baseParams);

    store.commit('analytics/setClickEvent', { getSiteControl: { eventName: null, eventData: null } });
  };

  // Check for store state before applying watcher
  handleNewClickEvent(store.state.analytics.tracker.getSiteControl.clickEventName);

  // Watch analytics store eventName change, set segmentId & userId
  store.watch(
    (state) => state.analytics.tracker.getSiteControl.clickEventName,
    async (clickEventName) => handleNewClickEvent(clickEventName)
  );

  store.watch(
    (state) => state.piano.access,
    async () => {
      setGscParams(baseParams);
    }
  );
};

export default GetSiteControlPlugin;
