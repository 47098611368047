import { PureQueryOptions } from '@apollo/client/core';
import fetchData from '../../apollo/resolvers/fetchData';
import { GetTagGroups } from '../../apollo/typings/getTagGroups';
import getTagGroups from '../../apollo/query/getTagGroups.graphql';
import { GetTagGroupsInput, ClientTypes } from '~/types';
import RedisClient from '../../redis/redisClient';

const error = {
  message: 'Tag groups not found.',
  statusCode: 404,
};

interface Variables {
  id?: GetTagGroupsInput['id'];
}

interface QueryOptions {
  variables?: GetTagGroups['variables'];
}

function getQueryOptions({ id }: Variables) {
  const queryOptions: QueryOptions = {
    variables: {
      id,
    },
  };

  return queryOptions;
}

const fetchTagGroups = async (variables?: Variables) => {
  const queryOptions = variables && getQueryOptions(variables);
  const options: PureQueryOptions = Object.assign({ query: getTagGroups, cacheData: false }, queryOptions);

  try {
    // If we have a cached entry send it
    const key = `fetchTagGroups::${JSON.stringify(variables)}`;
    const dataCache = await RedisClient.get(key);

    if (dataCache) {
      return JSON.parse(dataCache);
    }

    const recipes = await fetchData<GetTagGroups['data']>({ client: ClientTypes.recipes }, options);

    if (!recipes) {
      throw 'fetchTagGroups request is empty';
    }

    await RedisClient.set(key, recipes, 60 * 15);
    return recipes;
  } catch (e) {
    throw error;
  }
};

export default fetchTagGroups;
