import { DocumentNode } from '@apollo/client/core';

export type Access = {
  access: boolean;
};

export type AdFree = {
  results: {
    access: boolean;
    product: string;
  }[];
};

export type PaymentMethods = {
  methods: {
    type: string;
    method: string;
    price: number;
    link: string;
    mobileLink: string;
    linkType: string;
  }[];
};

export enum ClientTypes {
  recipes = 'recipes',
  paywall = 'paywall',
  user = 'user',
  customer = 'customer',
  userBatch = 'userBatch',
}

export interface ApiClient {
  client: ClientTypes;
  authToken?: string | null;
}

export enum Language {
  EN,
  ET,
  FI,
  LT,
  LV,
  RU,
}
export type RecipeWithRating = Recipe & { rating: number };

export enum ModalActions {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
}

export type RecipeBasket = {
  id: Recipe['id'];
  portions: number;
  recipe: RecipeWithRating & { ingredients: RecipeIngredient[] };
};

export type MutationOptions = {
  mutation: DocumentNode;
  variables?:
    | {
        [key: string]: any;
      }
    | undefined;
  context?: any;
};

export type DateType = Date;

// Date range input
export type DateRangeInput = {
  // From this date
  from: DateType;

  // To this date
  to: DateType;
};

export type GetRecipesInput = {
  // Get recipes by UUIDs
  id?: string[];

  // Recipe id
  externalId?: number;

  // Get recipes by tag ids
  tagId: string[];

  // Get recipes by tag group ids
  tagGroupId: string[];

  // Get recipes by ingredient ids
  ingredientId: string[];

  // Get recipe by statuses, no value means all statuses
  //
  // Permissions required if status other than PUBLISHED is queried!
  status: [RecipeStatus];

  // Get recipes between this date range
  createdAt: DateType;

  // Get recipes by logo existence, no value provided means both cases
  hasLogo: boolean;

  // Get recipes by search input
  search: SearchInput;

  // Get recipes pager input
  pager: PagerInput;

  // Get recipe description
  fetchPreparation: boolean;
};

// Recipe status enums
export enum RecipeStatus {
  // Draft state
  DRAFT = 'DRAFT',

  // Published state
  PUBLISHED = 'PUBLISHED',

  // Unpublished state
  UNPUBLISHED = 'UNPUBLISHED',
}

// Search input
export type SearchInput = {
  // List of fields that will be used in the search
  field: string[];

  // Value that will be used to match searched fields
  value: string;
};

// Pager input
export type PagerInput = {
  // Order by field
  orderBy?: string;

  // Order by direction
  orderDirection?: OrderDirection | string;

  // Limit results, default 50
  limit?: number;

  // Offset results, default 0
  offset?: number;
};

// Order by order directions
export enum OrderDirection {
  // Ascending
  ASC = 'ASC',

  // Descending
  DESC = 'DESC',
}

// Get recipes response
export type GetRecipesResponse = {
  // Array of recipes
  items: [Recipe];

  // Recipe pager
  pager: Pager;
};

// Recipe type
export type Recipe = {
  // Recipe id
  id: string;

  // Recipe externalId
  externalId: string;

  // Recipe title
  title: string;

  // Recipe title
  slug?: string;

  // Author of recipe
  author: string;

  // Recipe photo
  photo?: JSON;

  // Recipe preparation time
  preparationTime: number;

  // Recipe  simplicity
  difficulty: keyof typeof Difficulty;

  // Recipe preparation text
  preparationText: string;

  // Recipe preparation time unit
  preparationTimeUnit: string;

  // Recipe preparation photo
  preparationPhoto?: JSON;

  // Recipe portions
  portions: number;

  // Recipe blocks array
  blocks: [RecipeBlock];

  // Recipe tags array
  tags: Tag[];

  // Recipe logo url
  logoUrl: string;

  // Recipe logo url target when clicked
  logoTargetUrl: string;

  // Recipe calories amount
  calories: number;

  // Recipe status
  status?: RecipeStatus;

  // Date recipe is published
  //
  // If value is null, that means recipe isn't published
  publishedAt?: DateType;

  // Date when recipe was created
  createdAt?: DateType;

  // date when recipe was last updated
  updatedAt?: DateType;
};

export type ModifiedRecipe = Omit<Recipe, 'photo' | 'preparationPhoto'> & { photo: string; metaPhoto: string; preparationPhoto: string; photoAuthor: string };

// Recipe simplicity enum
export enum Difficulty {
  // Easy recipe
  EASY = 'Lihtne',

  // Medium recipe
  MEDIUM = 'Keskmine',

  // Difficult recipe
  DIFFICULT = 'Keeruline',
}

// Ingredient in recipe
export type RecipeIngredient = {
  // Ingredient order in recipe
  sortNumber?: number;
  // Quantity of ingredient in recipe
  quantity: number;

  // Ingredient in recipe
  ingredient: Ingredient;

  // Measurement used for this recipe ingredient
  measurement?: Measurement;
};

// Recipe Block type
export type RecipeBlock = {
  // Block Id
  id: string;

  // Block Title
  title: string;

  // Block sort number
  sortNumber: number;

  // Block ingredients
  ingredients: [RecipeIngredient];
};

// Ingredient type
export type Ingredient = {
  // Ingredient id
  id?: string;

  // Ingredient title
  title: string;

  // Ingredient logo url
  logoUrl?: string;

  // Ingredient logo url target when clicked
  logoTargetUrl?: string;

  // Measurement of ingredient
  measurement?: Measurement;

  // Date when ingredient was created
  createdAt?: DateType;

  // Date when ingredient was last updated
  updatedAt?: DateType;

  // Created by this user
  createdBy?: string;

  // Created by type
  createdByType?: ActorType;

  // Last updated by this user
  updatedBy?: string;

  // Last updated by type
  updatedByType?: ActorType;
};

// Measurement type
export type Measurement = {
  // Measurement id
  id?: string;

  // Measurement title
  title: string;

  // Measurement type
  type?: MeasurementType;

  // Date when measurement was created
  createdAt?: DateType;

  // Date when measurement was last updated
  updatedAt?: DateType;

  // Created by this user
  createdBy?: string;

  // Created by type
  createdByType?: ActorType;

  // Last updated by this user
  updatedBy?: string;

  // Last updated by type
  updatedByType?: ActorType;
};

// Measurement type for measurement
export type MeasurementType = {
  // System measurement uses
  system: MeasurementSystem;

  // Main measurement type for measurement
  //
  // Only 1 main can be set for each measurement system!
  main: boolean;

  // Measurement ratio with main measurement
  //
  // If main is false, provide ratio with measurement system
  // main measurement.
  //
  // Example:
  //
  // If liter is main measurement, and this measurement is milliliters.
  // Then ratio would be 1:0.001 - ratio: 0.001
  ratio: number;
};

// Measurement system
enum MeasurementSystem {
  // Measurement of the weight of an object
  WEIGHT,

  // Measurement of volume
  VOLUME,

  // Measurement of a piece
  PIECE,
}

// Actor types
export enum ActorType {
  // Service
  SERVICE,

  // Employee
  EMPLOYEE,

  // Customer
  CUSTOMER,
}

// Tag type
export type Tag = {
  // Tag id
  id: string;

  // Tag title
  title: string;

  // Tag group tag belongs to
  tagGroup?: TagGroup;

  // Recipes using this tag
  // recipes(input: GetRecipesInput): GetRecipesResponse;

  // Inactive/Active status
  status?: EntityStatus;

  // Date when tag was created
  createdAt?: DateType;

  // Date when tag was last updated
  updatedAt?: DateType;

  // Created by this user
  createdBy?: string;

  // Created by type
  createdByType?: ActorType;

  // Last updated by this user
  updatedBy?: string;

  // Last updated by type
  updatedByType?: ActorType;
};

// Tag group type
export type TagGroup = {
  // Tag group id
  id: string;

  // Tag group title
  title: string;

  // Inactive/Active status
  status: EntityStatus;

  // Tags using this tag group
  tags: GetTagsResponse;

  // Date when tag group was created
  createdAt: DateType;

  // Date when tag group was last updated
  updatedAt: DateType;

  // Created by this user
  createdBy: string;

  // Created by type
  createdByType: ActorType;

  // Last updated by this user
  updatedBy: string;

  // Last updated by type
  updatedByType: ActorType;
};

// Active/Inactive status enum
export enum EntityStatus {
  // Active
  ACTIVE,

  // Inactive
  INACTIVE,
}

// Get tags input
//
// Not providing input returns last updated tags
export type GetTagsInput = {
  // Get tags by UUIDs
  id: string[];

  // Get tags by tag group ids
  tagGroupId: string[];

  // Get tags by status, no value provided means both statuses
  status: EntityStatus;

  // Get tags by search input
  search: SearchInput;

  // Get tags pager input
  pager: PagerInput;
};

// Get tags response
export type GetTagsResponse = {
  // Array of tags
  items: Tag[];

  // Tag pager
  pager: Pager;
};

// Pager
export type Pager = {
  // Total amount of items
  count: number;

  // Number of which response items were offset to
  offset: number;

  // Number of which response items were limited to
  limit: number;
};

// Get tag groups input
//
// Not providing input returns tag groups by priority
export type GetTagGroupsInput = {
  // Get tag groups by UUIDs
  id?: string[];

  // Get tag groups by tag ids
  tagId?: string[];

  // Get tag groups by status, no value provided means both statuses
  status?: EntityStatus;

  // Get tag groups by search input
  search?: SearchInput;

  // Get groups pager input
  pager?: PagerInput;
};

// Get tag groups response
export type GetTagGroupsResponse = {
  // Array of tag groups
  items: [TagGroup];

  // Tag group pager
  pager: Pager;
};

// Get ingredients input
//
// Not providing input returns ingredients alphabetically
export type GetIngredientsInput = {
  // Get ingredients by UUIDs
  id: string[];

  // Get ingredients by status, no value provided means both statuses
  status: EntityStatus;

  // Get ingredients by logo existence, no value provided means both cases
  hasLogo: boolean;

  // Get ingredients by search input
  search: SearchInput;

  // Get ingredients pager input
  pager: PagerInput;
};

// Get ingredients response
export type GetIngredientsResponse = {
  // Array of ingredients
  items: [Ingredient];

  // Ingredient pager
  pager: Pager;
};

// Get measurements input
//
// Not providing input returns measurements alphabetically
export type GetMeasurementsInput = {
  // Get measurements by UUIDs
  id: string[];

  // Get measurements by status, no value provided means both statuses
  status: EntityStatus;

  // Get measurements by search input
  search: SearchInput;

  // Get measurements pager input
  pager: PagerInput;
};

// Get measurements response
export type GetMeasurementsResponse = {
  // Array of measurements
  items: [Measurement];

  // Measurement pager
  pager: Pager;
};

// Recipe input
export type CreateRecipeInput = {
  // Recipe title, required for publish
  title: string;

  // Recipe author, required for publish
  author: string;

  // Recipe photo, required for publish
  photo: JSON;

  // Preparation time (minutes), required for publish
  preparationTime: number;

  // Recipe portions, required for publish
  portions: number;

  // Recipe simplicity, required for publish
  difficulty: Difficulty;

  // Recipe logo, provide null to remove logo
  logoUrl: string;

  // Recipe logo redirect url, provide null to remove logoClickUrl
  logoClickUrl: string;

  // Recipe calories amount, provide null to remove calories
  calories: number;

  // Recipe ingredients
  //
  // Provide all ingredients when updating.
  // Requires atleast 1 ingredient for publish
  ingredients: RecipeIngredient[];

  // Recipe preparation text, required for publish
  preparationText: string;

  // Recipe preparation photo, can't be null
  preparationPhoto: JSON;

  // Recipe tags
  //
  // Provide all tags when updating.
  // Requires at least 1 tag for publish.
  tags: string[];

  // Recipe publish status
  //
  // Provide true to publish recipe
  // Provide false to set recipe to draft state
  publish: boolean;
};

// Object describing input params for Customer Data query
export type GetCustomerDataInput = {
  input: {
    key?: string | string[];
    customerId?: string | string[];
  };
};

// Object describing Customer Data entry
export type CustomerDataObject = {
  // Database id of Customer Data object
  id: string;

  // User id from jwt token
  customerId: number;

  // Type of object user is saving (article etc.)
  key: string;

  // Id of the object
  value: string;

  // DateTime when object is created
  createdAt: DateType;

  // DateTime when object was last updated
  updatedAt?: DateType;
};

// Object describing input params for Customer Data entry
export type CreateCustomerDataInput = {
  // Type of object user is saving (article etc.)
  key: string;

  // Value of object (article id etc.)
  value: string;
};

// Object describing input params for updating Customer Data object
export type UpdateCustomerDataInput = {
  // Id of Customer Data object
  id: string;

  // New type for object
  key?: string;

  // New value for object
  value: string;
};

export type BatchDeleteCustomerDataInput = {
  value: string;
  key: string;
};
