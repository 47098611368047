import { ErrorPolicy } from '@apollo/client';
import { ApiClient, MutationOptions } from '~/types';
import apolloClient from '../apolloClient';

interface CreateData<T = any> {
  data: T;
  errors: Error[];
}

interface CreateAndErrorPolicy {
  fetchPolicy: any;
  errorPolicy: ErrorPolicy;
}

const createData = async <T>(apiClient: ApiClient, queryOptions: MutationOptions) => {
  const createAndErrorPolicy: CreateAndErrorPolicy = { fetchPolicy: 'no-cache', errorPolicy: 'none' };
  const { ...mutateQueryOptions } = Object.assign({}, queryOptions, createAndErrorPolicy);
  try {
    const { data } = await apolloClient(apiClient.client, apiClient.authToken).mutate<CreateData<T>>(mutateQueryOptions);
    return data;
  } catch (error: any) {
    throw {
      debug: error,
      message: error?.message,
      statusCode: error?.networkError?.statusCode,
    };
  }
};
export default createData;
