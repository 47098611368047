
import Vue from 'vue';

interface Props {
  title: string;
  isChecked: boolean;
  isMobile: boolean;
}

interface Computed {
  checkbox: any;
}

interface Data {
  hover: boolean;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  props: {
    title: {
      type: String,
      default: '',
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isMobile: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
});
