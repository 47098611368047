
import Vue from 'vue';

interface Props {
  title: string;
  active: boolean;
  icon: string;
  disabled: boolean;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    title: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
