import { Plugin, Context } from '@nuxt/types';
import { cXense } from '@analytics/trackers';
import { ModifiedRecipe, Tag } from '~/types';
import config from '~/core/config/analytics.config';

type CustomParameters = Record<string, unknown>;

const cXensePlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;

  const cX = new cXense();

  try {
    await cX.init({ uid: config.cXense.siteId });
  } catch (error) {
    return;
  }

  const setCustomerId = () => {
    const externalId = store.state.piano.subscriptionInfo
      ? {
          id: store.state.piano.subscriptionInfo.customerIdHash,
          type: 'eks',
        }
      : null;

    cX.setExternalId(externalId);
  };

  const cXensePageView = (route: Context['route']) => {
    setCustomerId();

    const customParameters: CustomParameters = {};
    customParameters.recipe_folders_cnt = store.getters['customerFolders'].length;
    customParameters.recipe_fav_cnt = store.getters['favouriteRecipesIds'].length;
    customParameters.recipe_search_result = store.getters['totalNumberOfRecipesAvailable'];
    customParameters.recipe_filters = store.getters['searchTags'].map((tag: Tag) => tag.title || '') || [];
    customParameters.user_logged_in = store.state.piano.token ? 'yes' : 'no';

    if (route.name === 'recipe') {
      const recipe: ModifiedRecipe = store.getters['activeRecipe'];
      customParameters.recipe_keywords = (recipe && recipe.tags.map((tag: Tag) => tag.title || '')) || [];
    }

    cX.pageView({}, customParameters);
  };

  const startWatchingAccess = () => {
    store.watch(
      (state) => state.piano.access,
      async () => {
        cXensePageView(route);
      }
    );
  };

  if (store.state.piano.isScriptInited) {
    cXensePageView(route);
    startWatchingAccess();
  } else {
    store.watch(
      (state) => state.piano.isScriptInited,
      async () => {
        cXensePageView(route);
        startWatchingAccess();
      }
    );
  }

  app.router!.afterEach((to, from) => {
    if (from.name) {
      cXensePageView(to);
    }
  });

  // Get cXense segments and share them to trackers
  window.addEventListener('load', async () => {
    cX.push('getUserSegmentIds', {
      persistedQueryId: 'b04a959c2ea64c1ef725a6abb04ccecbbd9e6671',
      callback: async (segmentIds: string[]) => {
        store.commit('analytics/setClickEvent', {
          getSiteControl: {
            eventName: 'SYNC_SEGMENTS',
            eventData: { eventType: 'sync', data: { segmentIds } },
          },
        });
      },
    });
  });

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.cXense.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }

      if (!store.state.analytics.tracker.cXense.clickEventData) {
        return false;
      }

      cX.sendEvent(clickEventName, store.state.analytics.tracker.cXense.clickEventData, {
        origin: 'eks-retseptiveeb',
        persistedQueryId: config.cXense.persistedQueryId,
      });
      store.commit('analytics/setClickEvent', { cXense: { eventName: null, eventData: null } });
    }
  );
};

export default cXensePlugin;
