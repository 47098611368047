import Vue from 'vue';
import { NuxtAppOptions } from '@nuxt/types';
import VueI18n, { LocaleMessages } from 'vue-i18n';

async function importTranslations(locale: string) {
  // eslint-disable-next-line no-useless-catch
  try {
    const [recipeWebTranslations, portalCoreTranslations, portalPianoTranslations] = (await Promise.allSettled([
      import(/* webpackChunkName: "portal-root-[request]" */ `@/locales/${locale}.json`),
      import(/* webpackChunkName: "portal-core-[request]" */ `@core/locales/${locale}.json`),
      import(/* webpackChunkName: "portal-piano-[request]" */ `@piano/locales/${locale}.json`),
    ])) as { value?: LocaleMessages }[];
    return {
      ...recipeWebTranslations.value,
      ...portalCoreTranslations.value,
      ...portalPianoTranslations.value,
    };
  } catch (e) {
    throw e;
  }
}

Vue.use(VueI18n);

export default async ({ app }: { app: NuxtAppOptions }) => {
  const locale = 'et_EE';
  try {
    const translations = await importTranslations(locale);

    const i18n = new VueI18n({
      locale,
      fallbackLocale: locale,
      messages: {
        [locale]: translations,
      },
    });

    app.i18n = i18n;
  } catch (e) {
    const i18n = new VueI18n({ locale, fallbackLocale: locale });
    app.i18n = i18n;
  }
};
