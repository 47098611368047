
import Vue from 'vue';
import { mapGetters } from 'vuex';
import RecipeButton from '../UserProfile/shared/RecipeButton.vue';
import ModalHeader from './ModalHeader.vue';
import { PianoState } from '@piano/types/pianoState';

interface Props {
  name: string;
  placeholder: string;
  title: string;
  action: string;
  id: string | null;
  extraInfo: Record<string, string> | null;
}

interface Methods {
  addEditUserFolder: (val: string) => void;
  validateInput: (e: any) => void;
}

interface Data {
  val: string;
  error: string | null;
}

interface Computed {
  buttonTitle: string;
  customer: PianoState;
  recipeId: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    RecipeButton,
    ModalHeader,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
    },
    extraInfo: {
      type: [Object],
    },
  },
  data() {
    return {
      val: this.action === 'add' ? '' : this.name,
      error: null,
    };
  },
  computed: {
    ...mapGetters(['customer']),
    recipeId() {
      return this.extraInfo?.recipeId || null;
    },
    buttonTitle() {
      switch (this.action) {
        case 'add':
          return 'Lisa kaust';
        case 'edit':
          return 'kinnita';
        default:
          return '';
      }
    },
  },
  methods: {
    async addEditUserFolder() {
      let isSuccess = true;
      try {
        if (this.action === 'add') {
          const folderId = await this.$store.dispatch('addCustomerData', { key: 'recipe-web.folder', value: this.val });
          if (folderId && this.recipeId) {
            await this.$store.dispatch('addCustomerData', { key: `recipe-web.folder.${folderId}`, value: this.recipeId });
          }
        }
        if (this.id) {
          await this.$store.dispatch('updateCustomerData', {
            id: this.id,
            value: this.val,
          });
        }
      } catch (error) {
        isSuccess = false;
        this.$notify({ title: String(this.$t('words.error')), type: 'error' });
      } finally {
        this.$emit('close');
        const message = this.action === 'add' ? { title: this.val, type: 'folder' } : { title: `Kausta nimi muudetud!`, type: 'success' };
        isSuccess && this.$notify(message);
        isSuccess && this.recipeId && this.$notify({ title: this.val, type: 'favourite' });
        this.$store.commit('analytics/setClickEvent', {
          cXense: {
            eventName: 'recipe_favfolder_change',
            eventData: { folder_action: this.action.toLowerCase(), tag_name: this.val.toLowerCase(), tag_group: 'minu retseptid' },
          },
        });
      }
    },
    validateInput(e) {
      const value = e?.target?.value || '';
      this.error = value.length > 20 || value.length < 1 ? (this.error = 'Kaustale nime andmiseks sisesta maksimaalselt 20 tähemärki') : null;
    },
  },
});
