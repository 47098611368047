import { sync } from 'vuex-router-sync';
import { Plugin } from '@nuxt/types';

const storeRouterSync: Plugin = async (ctx) => {
  const { store, app } = ctx;
  const { router } = app;
  if (router) {
    sync(store, router);
  }
};

export default storeRouterSync;
