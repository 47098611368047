export default {
  mkw: ['channel_vue', 'channel_retseptiveeb'],
  mkv: {
    channel: 'retseptiveeb',
  },
  placements: {
    content: {
      mid: 1426384,
      lazyload: true,
      widthAtLeast: 768,
    },
    square: {
      mid: 1426415,
      lazyload: true,
    },
    mobile: {
      mid: 1426414,
      lazyload: true,
      widthAtMost: 767,
    },
  },
};
