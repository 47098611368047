const Redis = process.client ? require('./clientRedis.ts') : require('./serverRedis.ts');
class RedisClient {
  public static async get(key: string): Promise<any> {
    try {
      const data = await Redis.getFromRedis(key);
      return data;
    } catch (error) {
      return null;
    }
  }

  public static async set(key: string, data: any, expires = 30): Promise<void> {
    try {
      await Redis.setToRedis(key, JSON.stringify(data), expires);
    } catch (error) {
      console.error('Set to redis error: ', error);
    }
  }
}

export default RedisClient;
