
import Vue from 'vue';

import RecipeButton from '../shared/RecipeButton.vue';

interface Props {
  selected: number;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    RecipeButton,
  },
  props: {
    selected: { type: Number },
  },
});
