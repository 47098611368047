import { Plugin } from '@nuxt/types';
import { Gemius } from '@analytics/trackers';
import config from '~/core/config/analytics.config';

const GemiusPlugin: Plugin = async (ctx) => {
  const { app } = ctx;

  /*
   * setup gemius tracker
   */
  const gemiusTracker = new Gemius();
  try {
    await gemiusTracker.init({ uid: config.gemius.id });
  } catch (error) {
    return;
  }

  let firstPageLoad = true;

  app?.router?.afterEach(() => {
    if (!firstPageLoad) {
      gemiusTracker.sendPageEvent();
    }

    firstPageLoad = false;
  });
};

export default GemiusPlugin;
