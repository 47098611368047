import { MutationTree, GetterTree, ActionTree } from 'vuex';

import { PianoTp, PianoWindow, CheckoutStateChangeEvent, StartCheckoutOptions } from '@piano/types/pianoTp';
import { PianoModals, PianoState } from '@piano/types/pianoState';

import { loadScripts } from '@piano/utils/scriptLoader';
import { defaultLocale, pianoLocales, defaultAccess, defaultProfile, defaultExtendedProfile, defaultActiveCheckoutInfo } from '@piano/config/defaults';

import { checkUserAccess } from '@piano/utils/checkUserAccess';
import { collectExtendedProfile } from '@piano/utils/collectExtendedProfile';
import { collectSubscriptionInfo } from '@piano/utils/collectSubscriptionInfo';

import { setAdBlockCookie } from '@piano/utils/setAdBlockCookie';

import { getDefaultPromotion } from '@piano/utils/getDefaultPromotion';
import { getOfferType } from '@piano/utils/getOfferType';
import { getOfferConfiguration } from '@piano/utils/getOfferConfiguration';
import IpAccessService from '@piano/services/IpAccess.service';
import PianoIntegratorService from '@piano/services/PianoIntegrator.service';

interface AppPianoWindow extends PianoWindow {
  AndroidWebAppMobileAPI?: {
    openPianoSignIn?(): void;
  };
  iOSWebAppMobileAPI?: {
    openPianoSignIn?(): void;
  };
}

declare const window: AppPianoWindow;

const afterInit = async (callback: () => void, state: PianoState): Promise<unknown> => {
  const call = new Promise((resolve) => {
    const tp = window.tp;

    if (state.config.maintenanceMode) {
      resolve(undefined);
      return;
    }

    tp.push([
      'init',
      () => {
        const callbackResponse = callback();
        resolve(callbackResponse);
      },
    ]);
  });

  return await Promise.any([call]);
};

export const state: () => PianoState = () => ({
  config: {
    scriptSrc: null,
    aid: null,
    locale: defaultLocale,
    accessResources: {},
    offersConfiguration: {},
    defaultPromotions: {},
    maintenanceMode: false,
    isApplication: false,
  },
  scriptLoaded: false,
  scriptFailed: false,
  token: null,
  ipAccessToken: null,
  isLoggedIn: false,
  isScriptInited: false,
  experience: {
    ready: false, // script inited && experience inited
    inited: false,
    dispatchExperienceChangedEvent: false,
    validateCheckoutTerm: false,
    skipNextValidation: false,
  },
  isNewCustomer: false,
  profile: Object.assign({}, defaultProfile),
  access: Object.assign({}, defaultAccess),
  extendedProfile: Object.assign({}, defaultExtendedProfile),
  subscriptionInfo: null,
  modalsVisibility: {
    maintenanceAlert: false,
    sessionsLimitAlert: false,
  },
  activeCheckoutInfo: Object.assign({}, defaultActiveCheckoutInfo),
});

export const getters: GetterTree<PianoState, Record<string, unknown>> = {
  getTP: (state): PianoTp => {
    if (!state.scriptLoaded && typeof window.tp !== 'object') {
      console.warn('Piano is not initialized');
    }

    return window.tp;
  },
  getConfig: (state) => {
    return state.config;
  },
  getExtendedConfig: (state) => {
    return { config: state.config, defaultLocale, pianoLocales, defaultAccess, defaultProfile, defaultExtendedProfile };
  },
};

export const mutations: MutationTree<PianoState> = {
  setConfig(state, config: PianoState['config']) {
    state.config = config;
  },
  setProfile(state, profile: PianoState['profile']) {
    state.profile = profile;
  },
  setAccess(state, access: PianoState['access']) {
    state.access = access;
  },
  setExtendedProfile(state, extendedProfile: PianoState['extendedProfile']) {
    state.extendedProfile = extendedProfile;
  },
  setSubscriptionInfo(state, subscriptionInfo: PianoState['subscriptionInfo']) {
    state.subscriptionInfo = subscriptionInfo;
  },
};

export const actions: ActionTree<PianoState, Record<string, any>> = {
  async init({ state, dispatch, commit }, config: PianoState['config']) {
    commit('setConfig', config);

    if (state.config.maintenanceMode) {
      state.isLoggedIn = false;
      state.isScriptInited = true; // some logic like analytics rely on this event being true
      // set channel access?
      console.log('Maintenance mode is on');
      return;
    }
    if (state.scriptLoaded) {
      console.warn('Piano script is already initialized');
      return;
    }

    const tp = window.tp || [];

    tp.push(['setLocale', pianoLocales[state.config.locale] || defaultLocale]);

    tp.push([
      'init',
      () => {
        state.scriptLoaded = true;
        dispatch('initPianoId');
      },
    ]);

    tp.push([
      'addHandler',
      'loginSuccess',
      () => {
        dispatch('initUser', 'loginSuccess');
      },
    ]);

    tp.push([
      'addHandler',
      'checkoutClose',
      (event: { state: string }) => {
        dispatch('onCheckoutClose', event.state);
      },
    ]);

    tp.push([
      'addHandler',
      'customEvent',
      (event: { eventName: string; params: { params?: string } }) => {
        dispatch('dispatchCustomEvent', { eventName: event.eventName, params: event.params.params });
      },
    ]);

    tp.push([
      'addHandler',
      'checkoutStateChange',
      (event: CheckoutStateChangeEvent) => {
        dispatch('onCheckoutStateChange', event);
      },
    ]);

    tp.push([
      'addHandler',
      'experienceExecute',
      () => {
        dispatch('onExperienceExecution');
      },
    ]);

    window.tp = tp;

    if (config.ESPid) {
      window.PianoESPConfig = {
        id: config.ESPid,
      };
    }

    if (!state.config.scriptSrc || !state.config.aid) {
      console.warn('Piano settings are not provided');
      return;
    }

    try {
      document.cookie = '__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      await loadScripts('//www.npttech.com/advertising.js', { async: true, onerror: () => setAdBlockCookie(true) });
    } catch (e) {
      console.error(e);
    }

    try {
      await loadScripts(`${state.config.scriptSrc}`);
    } catch (e) {
      state.scriptFailed = true;
      console.log('Failed to load Piano', e);
    }
  },
  async setTags({ getters, state }, tags: string[]) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      /*
       * Usually, clients use Section for the site section (e.g., Sports, Fashion, etc.),
       * and Tags for everything else — including page type.
       * https://docs.piano.io/content-tracking/#custags
       * */
      tp.push(['setTags', tags]);

      /* Zones are specified on a page-by-page basis according to the JavaScript on a given page */
      // tp.push(['setZone', 'WEB']);
    }, state);
  },
  toggleModal({ state }, { name, show }: { name: PianoModals; show: boolean }) {
    state.modalsVisibility[name] = show;
  },
  async executeExperience({ getters, state }) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      window.composerOffersConfig = undefined;
      window.composerDefaultPromotions = undefined;
      window.composerCheckoutConfig = undefined;

      tp.experience.execute();
    }, state);
  },
  async showOfferByType({ getters, state }, options: { type: string; containerSelector: string; showPromotionComponent?: boolean }) {
    await afterInit(() => {
      const keyByLocale = `${options.type}_${state.config.locale}`;

      const offersConfiguration = state.config.offersConfiguration[keyByLocale] || state.config.offersConfiguration[options.type];

      let offer = getOfferConfiguration({
        hasRecentlyEndedSubscription: state.extendedProfile?.hasRecentlyEndedSubscription,
        offersConfiguration,
      });

      const composerOffer = window.composerOffersConfig?.[keyByLocale] || window.composerOffersConfig?.[options.type];

      if (composerOffer) {
        offer = composerOffer;
      }

      if (!offer) {
        return;
      }

      const tp: PianoTp = getters['getTP'];
      const containerSelector = options.containerSelector;

      const promoCode = getDefaultPromotion({
        promoCode: offer.promoCode,
        offerId: offer.offerId,
        composerPromotions: window.composerDefaultPromotions,
        promotions: state.config.defaultPromotions,
      });

      tp.push(['setCustomVariable', 'promoComponentVisibility', options.showPromotionComponent ? 'block' : 'none']);

      // Omit termId from offer to prevent checkout initiation
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      const { termId, displayMode = 'inline', showCloseButton = false, ...offerWoTermId } = offer;
      tp.offer.show({ ...offerWoTermId, containerSelector, promoCode, displayMode, showCloseButton });
    }, state);
  },
  async startCheckout({ getters, state, dispatch }, options: StartCheckoutOptions) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const { showPromotionComponent = false, checkoutConfigKey = '', ...pianoOptions } = options;
      const composerCheckoutOptions = window.composerCheckoutConfig?.[checkoutConfigKey];
      const checkoutOptions = composerCheckoutOptions || pianoOptions;

      const promoCode = getDefaultPromotion({
        promoCode: checkoutOptions.promoCode,
        offerId: checkoutOptions.offerId,
        composerPromotions: window.composerDefaultPromotions,
        promotions: state.config.defaultPromotions,
      });

      tp.push(['setCustomVariable', 'promoComponentVisibility', showPromotionComponent ? 'block' : 'none']);
      tp.offer.startCheckout({ ...checkoutOptions, promoCode });
    }, state);
  },

  async startCheckoutByType({ getters, state, dispatch }, options: { type: string; promoCode?: string; showPromotionComponent?: boolean }) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const { type, showPromotionComponent = false } = options;

      const offersConfiguration = state.config.offersConfiguration[type];
      if (!offersConfiguration) {
        console.log(`Missing offersConfiguration ${type}`);
        return;
      }

      let offer = getOfferConfiguration({
        hasRecentlyEndedSubscription: state.extendedProfile?.hasRecentlyEndedSubscription,
        offersConfiguration,
      });

      const composerOffer = window.composerOffersConfig?.[type];
      if (composerOffer) {
        offer = composerOffer;
      }

      if (!offer) {
        return;
      }

      const promoCode = getDefaultPromotion({
        promoCode: options.promoCode || offer.promoCode,
        offerId: offer.offerId,
        composerPromotions: window.composerDefaultPromotions,
        promotions: state.config.defaultPromotions,
      });

      tp.push(['setCustomVariable', 'promoComponentVisibility', showPromotionComponent ? 'block' : 'none']);
      tp.offer.startCheckout({ ...offer, promoCode, showCloseButton: true });
    }, state);
  },
  async showAccount({ getters, state, dispatch }, options) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      if (tp.pianoId.isUserValid()) {
        tp.myaccount.show(options);
        return true;
      } else {
        console.warn('user is not valid');
        return false;
      }
    }, state);
  },
  async showResetPassword({ getters, state, dispatch }, containerSelector) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      const tokenMatch = location.search.match(/reset_token=([A-Za-z0-9]+)/);

      if (!tp.pianoId.isUserValid() && tokenMatch) {
        const token = tokenMatch[1];

        tp.pianoId.show({
          resetPasswordToken: token,
          displayMode: 'inline',
          containerSelector,
          loggedIn: function () {
            location.reload();
          },
        });
        return true;
      } else {
        console.warn('user is not valid or no token');
        return false;
      }
    }, state);
  },
  async resetUser({ state, dispatch }) {
    const pianoIntegratorApiUrl = state.config.pianoIntegratorApiUrl;
    const token = state.token;

    if (pianoIntegratorApiUrl && token) {
      const pianoIntegratorService = new PianoIntegratorService(pianoIntegratorApiUrl);
      const responseStatus = await pianoIntegratorService.resetUser(token);
      dispatch('dispatchEvent', 'resetUserCompleted');
      return responseStatus;
    }

    return false;
  },
  async showLoginModal({ getters, state, dispatch }, screen) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    const isApplication = state.config.isApplication;

    if (isApplication && window.AndroidWebAppMobileAPI?.openPianoSignIn) {
      window.AndroidWebAppMobileAPI.openPianoSignIn();
      return;
    } else if (isApplication && window.iOSWebAppMobileAPI?.openPianoSignIn) {
      window.iOSWebAppMobileAPI.openPianoSignIn();
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      // https://docs.piano.io/track/id-implementation/#idparameters
      tp.pianoId.show({
        displayMode: 'modal',
        screen, // login, register, restore, new_password
        ...(state.config.isApplication && { stage: 'application' }),
        disableSignUp: false,
        loginFailed: function (response: unknown) {
          console.warn(response);
        },
      });
    }, state);
  },
  async showLoginForm({ getters, state, dispatch }, containerSelector) {
    if (state.config.maintenanceMode) {
      dispatch('toggleModal', { name: 'maintenanceAlert', show: true });
      return;
    }

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      // https://docs.piano.io/track/id-implementation/#idparameters
      tp.pianoId.show({
        containerSelector,
        displayMode: 'inline',
        screen: 'login',
      });
    }, state);
  },
  async getRawUser({ getters, state }) {
    return await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      return tp.pianoId.getUser();
    }, state);
  },
  async logout({ getters, state }) {
    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];

      return tp.pianoId.logout();
    }, state);
  },
  async onCheckoutClose({ dispatch, getters, state }, eventName) {
    dispatch('dispatchEvent', eventName);

    state.activeCheckoutInfo = Object.assign({}, defaultActiveCheckoutInfo);

    await afterInit(() => {
      const tp: PianoTp = getters['getTP'];
      tp.push(['setCustomVariable', 'promoComponentVisibility', 'none']);
    }, state);

    switch (eventName) {
      case 'checkoutCompleted':
        break;
      case 'alreadyHasAccess':
        break;
      case 'voucherRedemptionCompleted':
        break;
      case 'close':
        break;
    }
  },
  async initPianoId({ getters, dispatch }, payload: { clean: boolean }) {
    const tp: PianoTp = getters['getTP'];
    if (payload?.clean) {
      await tp.pianoId.init();
    }

    await tp.pianoId.init({
      profileUpdate: async () => {
        dispatch('initUser', 'updateProfile');
      },
      registrationSuccess: () => {
        dispatch('initUser', 'registrationSuccess');
      },
      loggedOut: () => {
        dispatch('initUser', 'logout');
      },
    });

    dispatch('initUser', 'init');
  },
  async initUser({ getters, state, commit, dispatch }, eventName) {
    const tp: PianoTp = getters['getTP'];

    state.token = tp.pianoId.getToken();
    state.isNewCustomer = state.isNewCustomer || eventName === 'registrationSuccess';

    // if (eventName === 'registrationSuccess') {
    //   return; // loginSuccess will be fired also on registration
    // }

    let isLoggedIn = false;
    let profileData = Object.assign({}, defaultProfile);
    let accessData = Object.assign({}, defaultAccess);
    let extendedProfileData = Object.assign({}, defaultExtendedProfile);
    let subscriptionInfoData = null;

    if (state.token && tp.pianoId.isUserValid()) {
      isLoggedIn = true;

      const pianoUserData = tp.pianoId.getUser();

      profileData = {
        lastName: pianoUserData?.lastName || '',
        firstName: pianoUserData?.firstName || '',
        email: pianoUserData?.email || '',
        uid: pianoUserData?.uid || '',
        sub: pianoUserData?.sub || '',
        emailConfirmationRequired: pianoUserData?.email_confirmation_required || false,
        confirmed: pianoUserData?.confirmed || false,
        valid: pianoUserData?.valid || false,
      };

      const accessListPromise = new Promise((resolve) => {
        tp.api.callApi('/access/list', {}, async (response) => {
          if (response.data) {
            accessData = {
              channelAccess: checkUserAccess(state.config.accessResources.channelAccess, response.data),
              adFree: checkUserAccess(state.config.accessResources.adFree, response.data),
            };

            subscriptionInfoData = await collectSubscriptionInfo(state.config.accessResources.channelAccess, response.data);
            resolve(true);
          }
          resolve(true);
        });
      });

      const extendedUserPromise = new Promise((resolve) => {
        tp.pianoId.loadExtendedUser({
          extendedUserLoaded: (data) => {
            extendedProfileData = collectExtendedProfile(data);
            resolve(true);
          },
          formName: 'ExtendedData',
        });
      });

      try {
        await Promise.all([accessListPromise, extendedUserPromise]);
      } catch (e) {
        console.log(e);
      }
    }

    if (state.config.ipAccessApiUrl) {
      const ipAccessService = new IpAccessService({ apiUrl: state.config.ipAccessApiUrl });
      const ipApiAccessData = await ipAccessService.checkAccess(state.token);

      if (ipApiAccessData.access) {
        state.ipAccessToken = ipApiAccessData.token;
        accessData.channelAccess = true;
      }
    }

    commit('setProfile', profileData);
    commit('setAccess', accessData);
    commit('setExtendedProfile', extendedProfileData);
    commit('setSubscriptionInfo', subscriptionInfoData);

    state.isLoggedIn = isLoggedIn;
    state.isScriptInited = true;
    dispatch('setExperienceReady');

    dispatch('dispatchEvent', eventName);

    if (['loginSuccess', 'logout', 'registrationSuccess'].includes(eventName)) {
      const { activeCheckoutInfo } = state;
      state.experience.dispatchExperienceChangedEvent = true;

      if (eventName === 'loginSuccess' && activeCheckoutInfo.termName && activeCheckoutInfo.startedWithoutAuthentication) {
        state.experience.validateCheckoutTerm = true;
      }

      dispatch('executeExperience');
    }
  },
  onExperienceExecution({ state, dispatch }) {
    state.experience.inited = true;
    dispatch('setExperienceReady');

    if (state.experience.dispatchExperienceChangedEvent) {
      state.experience.dispatchExperienceChangedEvent = false;
      dispatch('dispatchEvent', 'experienceChanged');
    }

    if (state.experience.validateCheckoutTerm) {
      state.experience.validateCheckoutTerm = false;
      dispatch('validateCheckoutTerm');
    }
  },
  setExperienceReady({ state }) {
    state.experience.ready = state.isScriptInited && state.experience.inited;
  },
  onCheckoutStateChange({ state, dispatch }, event: CheckoutStateChangeEvent) {
    if (event.stateName !== 'offer') {
      state.activeCheckoutInfo.offerId = event.offerId;
      state.activeCheckoutInfo.termId = event.term.termId;
      state.activeCheckoutInfo.termName = event.term.name;

      /* On checkout opening state */
      if (event.stateName === 'state2') {
        state.activeCheckoutInfo.startedWithoutAuthentication = !state.isLoggedIn;
        dispatch('validateCheckoutTerm');
        dispatch('updatePreviousSubscriptionNotice');
      }
    }
  },
  validateCheckoutTerm({ state, getters }) {
    if (!state.activeCheckoutInfo.offerId || state.experience.skipNextValidation) {
      state.experience.skipNextValidation = false;
      return;
    }

    const offerType = getOfferType(state.activeCheckoutInfo.offerId, state.config.offersConfiguration);

    if (!offerType) {
      return;
    }

    const offersConfiguration = state.config.offersConfiguration[offerType];
    const offer = getOfferConfiguration({
      hasRecentlyEndedSubscription: state.extendedProfile?.hasRecentlyEndedSubscription,
      offersConfiguration,
    });

    if (!offer || offer.offerId === state.activeCheckoutInfo.offerId) {
      return;
    }

    const tp: PianoTp = getters['getTP'];

    tp.offer.close();

    const promoCode = getDefaultPromotion({
      promoCode: offer.promoCode,
      offerId: offer.offerId,
      composerPromotions: window.composerDefaultPromotions,
      promotions: state.config.defaultPromotions,
    });

    tp.push(['setCustomVariable', 'promoComponentVisibility', 'none']);
    tp.offer.startCheckout({ ...offer, promoCode, showCloseButton: true });
    state.experience.skipNextValidation = true;
  },
  async updatePreviousSubscriptionNotice({ state }) {
    const offerType = getOfferType(state.activeCheckoutInfo.offerId, state.config.offersConfiguration);

    if (!offerType) {
      return;
    }

    const offersConfiguration = state.config.offersConfiguration[offerType];
    const returningCustomerOffer = Boolean(offersConfiguration['returningCustomer'] && state.extendedProfile?.hasRecentlyEndedSubscription);

    if (!returningCustomerOffer) {
      return;
    }

    const pianoIframe = document.querySelector<HTMLIFrameElement>('.tp-modal[style*="display: block;"] iframe');

    const payload = JSON.stringify({
      type: 'setPreviousSubscriptionNoticeDisplay',
      display: returningCustomerOffer ? 'block' : 'none',
    });

    if (pianoIframe?.contentWindow) {
      pianoIframe.contentWindow.postMessage(payload, '*');
    }
  },
  dispatchEvent() {
    // this store action could be listened
  },
  dispatchCustomEvent() {
    // this store action could be listened
  },
};

export const pianoStoreModule = {
  piano: {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
  },
};
