import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import { CookieStatsOptions, CookieStatsParams } from './types/CookieStats';

import logger from '@analytics/util/logger';
import { loadScripts } from '@analytics/util/scriptLoader';

declare let window: Awindow;

export default class CookieStats implements Analytics {
  private options!: CookieStatsOptions;
  private dct: Awindow['_dct'];

  /**
   * Load script and initialize tracker
   */
  public async init(options: CookieStatsOptions) {
    this.options = options;

    await this.loadScript();

    if (!this.options?.site) {
      logger.error('cookie stats site not provided');
      return;
    }

    if (!window._dct) {
      logger.error('cookie stats script does not exists on page');
      return;
    }

    this.dct = window._dct;
  }

  public sendPageEvent(params: Partial<CookieStatsParams> = {}) {
    if (!this.dct) {
      logger.error('cookie stats script does not exists on page');
      return;
    }

    const options: CookieStatsParams = {
      ...params,
      s: this.options.site,
    };

    if (this.options?.domain) {
      options.d = this.options.domain;
    }

    this.dct(options);
  }

  private async loadScript() {
    if (!window._dct) {
      const script = `http${
        window.location.protocol === 'https:' ? 's://' : '://'
      }g.delfi.ee/scms/?g=t`;

      try {
        await loadScripts(script);
      } catch (e) {
        console.error('CookieStats loadScript error', e);
      }
    }
  }
}
