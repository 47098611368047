var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-recipe"},[_c('ModalHeader',{attrs:{"title":_vm.title,"action":() => _vm.modalAction()}}),_vm._v(" "),_c('div',{staticClass:"add-recipe__row"},[_c('div',{staticClass:"add-recipe__row__folders"},[_c('div',{staticClass:"add-recipe__row__folders__wrapper"},[_vm._l((_vm.customerFolders),function(folder){return _c('div',{key:folder.id},[_c('div',{staticClass:"add-recipe__row__folders__wrapper__folder",class:{
              'add-recipe__row__folders__wrapper__folder__active': _vm.showActive(folder.id),
            },on:{"click":function($event){return _vm.addEditFolders(folder.id, folder.value)},"mouseover":function($event){!_vm.isMobile ? (_vm.hover = folder.id) : null},"mouseleave":function($event){!_vm.isMobile ? (_vm.hover = null) : null}}},[((_vm.hover === folder.id || _vm.isInFolder(folder.id)) && _vm.dynamicFolderIcon(folder.id))?_c('Icon',{staticClass:"add-recipe__row__folders__wrapper__folder__in-folder-icon",attrs:{"name":_vm.dynamicFolderIcon(folder.id)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"add-recipe__row__folders__wrapper__folder__icon"},[_c('Icon',{class:{
                  'add-recipe__row__folders__wrapper__folder__icon': true,
                  'add-recipe__row__folders__wrapper__folder__icon-desktop': _vm.isTwoRowFolderTitleMobile(folder.value),
                  'add-recipe__row__folders__wrapper__folder__icon-active': _vm.showActive(folder.id),
                },attrs:{"name":"folder"}})],1),_vm._v(" "),_c('p',{staticClass:"add-recipe__row__folders__wrapper__folder__title",class:{
                'add-recipe__row__folders__wrapper__folder__title-active': _vm.showActive(folder.id),
                'add-recipe__row__folders__wrapper__folder__title-long': _vm.isTwoRowFolderTitleMobile(folder.value),
              }},[_vm._v("\n              "+_vm._s(folder.value)+"\n            ")])],1)])}),_vm._v(" "),_c('RecipeButton',{staticClass:"add-recipe__row__folders__wrapper__button add-recipe__desktop",attrs:{"icon":"new","title":_vm.buttonTitle},nativeOn:{"click":function($event){return (() => _vm.$store.commit('changeModalCenterType', { centerType: 'add', id: null, extraInfo: { recipeId: _vm.id } })).apply(null, arguments)}}})],2)])]),_vm._v(" "),_c('RecipeButton',{staticClass:"add-recipe__button",attrs:{"title":"Salvesta"},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }