import { AccessObject } from '@piano/types/pianoTp';
import { PianoState } from '@piano/types/pianoState';
import dayjs from 'dayjs';

const hmacSha256 = async (str: string | number, secret: string) => {
  const enc = new TextEncoder();
  const key = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' },
    },
    false,
    ['sign', 'verify']
  );
  const signature = await window.crypto.subtle.sign('HMAC', key, enc.encode(str.toString()));
  const b = new Uint8Array(signature);
  return Array.prototype.map.call(b, (x) => x.toString(16).padStart(2, '0')).join('');
};

const hashCustomerId = async (id: string) => {
  if (process.client) {
    return await hmacSha256(id, 'cxensesecret');
  } else {
    return '';
  }
};

export const collectSubscriptionInfo = async (ridArray: string[], userAccesses: AccessObject[]): Promise<PianoState['subscriptionInfo'] | null> => {
  let access: AccessObject | null = null;

  access = userAccesses.find((obj) => ridArray.includes(obj.resource.rid) && obj.granted) || null;

  if (access) {
    const startDate = dayjs.unix(access.start_date);

    return {
      rid: access.resource.rid,
      type: access.parent_access_id ? 'shared' : 'main',
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      name: access.resource.name,
      customerIdHash: await hashCustomerId(access.user.uid),
    };
  } else {
    return null;
  }
};
