interface IdType {
  id?: string[];
  externalId?: number;
}

const resolveIdType = (id: string) => {
  const idType: IdType = {};
  if (isNaN(Number(id))) {
    idType.id = [id];
  } else {
    idType.externalId = Number(id);
  }

  return idType;
};

export default resolveIdType;
