export default {
  cXense: {
    siteId: '1145189970857384309',
    persistedQueryId: '098ef78210c85cdb4f34210da2b52cb47afb27b1',
  },
  gemius: {
    id: 'cnuQ.celN9SXb2yFwkn3E7e_.Ik84z.jAKYqKvmPbrP.67',
  },
  getSiteControl: {
    url: '//l.getsitecontrol.com/zwxr83yw.js',
  },
  clarity: {
    key: 'dhcdwcu0be',
  },
};
