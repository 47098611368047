
import Vue from 'vue';
import PianoCustomerStateHandler from '../../../libraries/piano/src/components/PianoCustomerStateHandler.vue';

interface Data {
  title: string;
}

export default Vue.extend<Data, unknown, unknown, unknown>({
  components: {
    PianoCustomerStateHandler,
  },
  data() {
    return {
      title: 'Logi välja',
    };
  },
});
