import { Plugin } from '@nuxt/types';

import { InstanceConnector } from '@ekd/portal-connector/dist/InstanceConnector';

const instanceConnector: Plugin = (ctx, inject) => {
  const instanceConnector = new InstanceConnector({ type: 'Nuxt' });
  const instance = instanceConnector.addContext({ store: ctx.store, $eventHandler: ctx.app.$eventHandler }).connect();

  inject('instanceConnector', instance.install());
};

export default instanceConnector;
