
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { WithContext, WebSite } from 'schema-dts';

import UserNavigation from '@/components/Navigation/UserNavigation.vue';
import DeleteModalContent from '@/components/UI/Modals/DeleteModalContent.vue';
import AddEditModalContent from '@/components/UI/Modals/AddEditModalContent.vue';
import MobileHeader from '@/components/Navigation/MobileHeader.vue';
import MobileCenterModalContent from '@/components/UI/Modals/MobileCenterModalContent.vue';
import AddRecipe from '@/components/UI/Modals/AddRecipe.vue';
import RightModalNavigation from '@/components/Navigation/RightModalNavigation.vue';

import { ModalActions } from '~/types';
import adsConfig from '@/core/config/ads.config';
import portalConfig from '@/core/config/portal.config';
import NotificationMessage from '~/components/UI/Modals/NotificationMessage.vue';

const PianoMaintenanceAlert = () => import('@piano/components/PianoMaintenanceAlert.vue');

interface AfpWindow extends Window {
  DelfiAFPcounter: Record<string, unknown>;
}

declare let window: AfpWindow;

interface Data {
  displaySideNav: boolean;
  method: string | null;
  name: string | null;
  placeholder: string | null;
  title: string | null;
  action: string | null;
  id: string | null | undefined;
  extraInfo: null | Record<string, string>;
  isMobile: boolean;
  portalConfig: typeof portalConfig;
  modalComponent: string | null | undefined;
}

interface Methods {
  toggleModal: (name: string, method: string, modal?: string, id?: string) => void;
  closeModal: (modal: string) => void;
  isModalOpen: (modal: string) => boolean;
  windowResize: () => void;
  setLoader: () => void;
  resetAdFormKey: () => void;
}
interface Computed {
  modals: Record<string, any>;
  anyModalOpen: boolean;
  centerModalType: string;
  isError: boolean;
  modalsExtraInfo: Record<string, string> | null;
  showPianoMaintenanceAlert: boolean;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  name: 'default',
  components: {
    NotificationMessage,
    UserNavigation,
    DeleteModalContent,
    AddEditModalContent,
    MobileHeader,
    MobileCenterModalContent,
    AddRecipe,
    RightModalNavigation,
    PianoMaintenanceAlert,
  },
  data() {
    return {
      portalConfig,
      displaySideNav: false,
      method: null,
      name: null,
      placeholder: null,
      action: null,
      title: null,
      id: null,
      extraInfo: null,
      isMobile: false,
      modalComponent: undefined,
    };
  },
  jsonld() {
    const { meta } = this.portalConfig;
    const pageJsonld: WithContext<WebSite> = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: meta.metaUrl,
      author: {
        '@type': 'Person',
        name: meta.title,
      },
      publisher: {
        '@type': 'Organization',
        name: 'Delfi',
      },
      description: meta.description,
    };
    return pageJsonld;
  },
  head() {
    return {
      bodyAttrs: {
        class: this.anyModalOpen ? 'no-scroll' : '',
      },
    };
  },
  computed: {
    ...mapGetters({ modals: 'isModalOpen', isError: 'isError', token: 'token' }),
    anyModalOpen() {
      return Object.values(this.modals).includes(true);
    },
    centerModalType() {
      return this.modals.centerType;
    },
    modalsExtraInfo() {
      return this.modals.extraInfo;
    },
    showPianoMaintenanceAlert() {
      return this.$store.state.piano.maintenanceAlertVisible;
    },
  },
  methods: {
    setLoader() {
      this.$nuxt.$loading.start();
      setTimeout(() => this.$nuxt.$loading.finish(), 300);
    },
    isModalOpen(modal) {
      return this.modals[modal];
    },
    toggleModal(name, method, modal, id) {
      const newModal = { modal, centerType: method, id };
      this.$store.commit('toggleModalNoCloseOtherModals', newModal);
      this.$store.commit('pushModalHistory', newModal);
      this.name = name;
      this.id = id;
    },
    closeModal(modal) {
      this.$store.commit('popModalsHistory');
      this.$store.commit('toggleModalNoCloseOtherModals', { modal, centerType: null, id: null });
    },
    windowResize() {
      const desktop = 992;
      const width = document?.documentElement?.clientWidth;
      if (width >= desktop && this.isModalOpen('left')) {
        this.$store.commit('toggleModal', { modal: 'left', centerType: null });
      }
      if (width < desktop) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    // Reset adForm counter on keywords change
    resetAdFormKey() {
      if (window && window.DelfiAFPcounter) {
        window.DelfiAFPcounter = {};
      }
    },
  },
  watch: {
    token(newValue) {
      if (!newValue) {
        this.$store.dispatch('afterLogout');
      }
    },
    async $route() {
      if (process.client) {
        this.setLoader();
        this.resetAdFormKey();
      }
    },
    modals() {
      this.id = this.modals.id;
    },
    isError(isTrue) {
      isTrue && this.$notify({ title: String(this.$t('words.error')), type: 'error' });
      isTrue && this.$nuxt.error({ message: String(this.$t('words.error')) });
    },
    centerModalType: {
      handler: function (newVal) {
        switch (newVal) {
          case ModalActions.edit:
            this.placeholder = this.name;
            this.title = `Muuda nimetust kaustal "${this.name}"`;
            this.action = ModalActions.edit;
            this.modalComponent = 'AddEditModalContent';
            return;
          case ModalActions.delete:
            this.action = ModalActions.delete;
            this.modalComponent = 'DeleteModalContent';
            return;
          case ModalActions.add:
            this.title = 'Loo uus kaust:';
            this.placeholder = 'Lisa kausta nimi';
            this.action = ModalActions.add;
            this.extraInfo = this.modalsExtraInfo;
            this.modalComponent = 'AddEditModalContent';
            return;
          case 'mobile':
            this.title = this.name;
            this.modalComponent = 'MobileCenterModalContent';
            return;
          case 'favourite':
            this.modalComponent = 'AddRecipe';
            return;
          default:
            this.modalComponent = undefined;
            return;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const { $route, $store } = this;
    const { query } = $route;

    if ('search' in query) {
      $store.commit('setSearchTerm', query.search);
    }

    $store.commit('adForm/setDefaultAdsSettings', adsConfig);

    this.$nextTick(() => {
      this.setLoader();
    });
    this.windowResize();
    document?.addEventListener('resize', this.windowResize);
  },
  beforeDestroy() {
    document?.removeEventListener('resize', this.windowResize);
  },
});
