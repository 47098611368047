import deleteData from '../../apollo/resolvers/deleteData';
import { DeleteCustomerData } from '../../apollo/typings/deleteCustomerData';
import mutation from '../../apollo/query/deleteCustomerData.graphql';
import { ApiClient, MutationOptions } from '~/types';

const error = {
  message: 'customer data not found.',
  statusCode: 404,
};

const deleteCustomerData = async (client: ApiClient, variables?: DeleteCustomerData['variables']) => {
  const queryOptions = { variables };
  const options: MutationOptions = Object.assign({ mutation, cacheData: false }, queryOptions);

  try {
    const customerData = await deleteData<DeleteCustomerData['data']>(client, options);

    if (!customerData) {
      throw 'deleteCustomerData request is empty';
    }
    return customerData;
  } catch (e) {
    throw error;
  }
};

export default deleteCustomerData;
