import { MutationTree, GetterTree } from 'vuex';

type ClickEventName = string | null;
type ClickEventData = Record<string, any> | null | undefined;

interface State {
  tracker: Record<
    string,
    {
      clickEventName: ClickEventName;
      clickEventData: ClickEventData;
    }
  >;
}

export const state: () => State = () => ({
  tracker: {
    cXense: {
      clickEventName: null,
      clickEventData: null,
    },
    getSiteControl: {
      clickEventName: null,
      clickEventData: null,
    },
  },
});
type Payload = Record<string, { eventName: ClickEventName; eventData?: ClickEventData }>;

export const getters: GetterTree<State, Record<string, unknown>> = {};

export const mutations: MutationTree<State> = {
  setClickEvent(state, payload: Payload) {
    Object.keys(payload).forEach((trackerType) => {
      state.tracker[trackerType] = {
        clickEventName: payload[trackerType].eventName,
        clickEventData: payload[trackerType].eventData ? payload[trackerType].eventData : null,
      };
    });
  },
};
