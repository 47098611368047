
import Vue from 'vue';

interface Props {
  isLoaded: boolean;
  isMobile: boolean;
}

interface Computed {
  text: string;
}

interface Data {
  hover: boolean;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  props: {
    isLoaded: { type: Boolean, required: true },
    isMobile: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    text() {
      return this.isLoaded ? 'Näita vähem' : 'Näita rohkem';
    },
  },
});
