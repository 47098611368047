export default <T extends Record<string, any>>(arr: Array<T>, paramToGroupBy: string, paramValue: string): Record<string, any> => {
  const groupedBy: Record<string, any> = {};
  Array.isArray(arr) &&
    arr.forEach((r: T) => {
      if (!(r[paramToGroupBy] in groupedBy)) {
        groupedBy[r[paramToGroupBy]] = [r[paramValue]];
      } else {
        const val = r[paramValue];
        groupedBy[r[paramToGroupBy]] = [...groupedBy[r[paramToGroupBy]], val];
      }
    });
  return groupedBy;
};
