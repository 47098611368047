import createData from '../../apollo/resolvers/createData';
import { CreateCustomerData } from '../../apollo/typings/createCustomerData';
import mutation from '../../apollo/query/createCustomerData.graphql';
import { ApiClient, MutationOptions } from '~/types';

const error = {
  message: 'create data not found.',
  statusCode: 404,
};

const createCustomerData = async (client: ApiClient, variables?: CreateCustomerData['variables']) => {
  const queryOptions = { variables };
  const options: MutationOptions = Object.assign({ mutation, cacheData: false }, queryOptions);

  try {
    const customerData = await createData<CreateCustomerData['data']>(client, options);

    if (!customerData) {
      throw 'customerData request is empty';
    }
    return customerData;
  } catch (e) {
    throw error;
  }
};

export default createCustomerData;
