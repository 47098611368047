export const LandingInfoBlock = () => import('../../components/Landing/InfoBlock.vue' /* webpackChunkName: "components/landing-info-block" */).then(c => wrapFunctional(c.default || c))
export const LandingLoggedInActions = () => import('../../components/Landing/LoggedInActions.vue' /* webpackChunkName: "components/landing-logged-in-actions" */).then(c => wrapFunctional(c.default || c))
export const NavigationMobileHeader = () => import('../../components/Navigation/MobileHeader.vue' /* webpackChunkName: "components/navigation-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const NavigationModal = () => import('../../components/Navigation/Modal.vue' /* webpackChunkName: "components/navigation-modal" */).then(c => wrapFunctional(c.default || c))
export const NavigationRightModalNavigation = () => import('../../components/Navigation/RightModalNavigation.vue' /* webpackChunkName: "components/navigation-right-modal-navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationUserNavigation = () => import('../../components/Navigation/UserNavigation.vue' /* webpackChunkName: "components/navigation-user-navigation" */).then(c => wrapFunctional(c.default || c))
export const EmbedsRecipeEmbed = () => import('../../components/embeds/RecipeEmbed.vue' /* webpackChunkName: "components/embeds-recipe-embed" */).then(c => wrapFunctional(c.default || c))
export const EmbedsApp = () => import('../../components/embeds/app.js' /* webpackChunkName: "components/embeds-app" */).then(c => wrapFunctional(c.default || c))
export const EmbedsEmbed = () => import('../../components/embeds/embed.ts' /* webpackChunkName: "components/embeds-embed" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyFilterBtn = () => import('../../components/UI/MainBody/FilterBtn.vue' /* webpackChunkName: "components/u-i-main-body-filter-btn" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyGoBackBtn = () => import('../../components/UI/MainBody/GoBackBtn.vue' /* webpackChunkName: "components/u-i-main-body-go-back-btn" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyHeaderFilters = () => import('../../components/UI/MainBody/HeaderFilters.vue' /* webpackChunkName: "components/u-i-main-body-header-filters" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyHeaderWithLogo = () => import('../../components/UI/MainBody/HeaderWithLogo.vue' /* webpackChunkName: "components/u-i-main-body-header-with-logo" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRating = () => import('../../components/UI/MainBody/Rating.vue' /* webpackChunkName: "components/u-i-main-body-rating" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodySearchBar = () => import('../../components/UI/MainBody/SearchBar.vue' /* webpackChunkName: "components/u-i-main-body-search-bar" */).then(c => wrapFunctional(c.default || c))
export const UIModalsAddEditModalContent = () => import('../../components/UI/Modals/AddEditModalContent.vue' /* webpackChunkName: "components/u-i-modals-add-edit-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UIModalsAddRecipe = () => import('../../components/UI/Modals/AddRecipe.vue' /* webpackChunkName: "components/u-i-modals-add-recipe" */).then(c => wrapFunctional(c.default || c))
export const UIModalsDeleteModalContent = () => import('../../components/UI/Modals/DeleteModalContent.vue' /* webpackChunkName: "components/u-i-modals-delete-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UIModalsMobileCenterModalContent = () => import('../../components/UI/Modals/MobileCenterModalContent.vue' /* webpackChunkName: "components/u-i-modals-mobile-center-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UIModalsModalHeader = () => import('../../components/UI/Modals/ModalHeader.vue' /* webpackChunkName: "components/u-i-modals-modal-header" */).then(c => wrapFunctional(c.default || c))
export const UIModalsNotificationMessage = () => import('../../components/UI/Modals/NotificationMessage.vue' /* webpackChunkName: "components/u-i-modals-notification-message" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileFooter = () => import('../../components/UI/UserProfile/Footer.vue' /* webpackChunkName: "components/u-i-user-profile-footer" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileLogin = () => import('../../components/UI/UserProfile/Login.vue' /* webpackChunkName: "components/u-i-user-profile-login" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileLogoBox = () => import('../../components/UI/UserProfile/LogoBox.vue' /* webpackChunkName: "components/u-i-user-profile-logo-box" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileLogout = () => import('../../components/UI/UserProfile/Logout.vue' /* webpackChunkName: "components/u-i-user-profile-logout" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileMyRecipes = () => import('../../components/UI/UserProfile/MyRecipes.vue' /* webpackChunkName: "components/u-i-user-profile-my-recipes" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileRecipeFolder = () => import('../../components/UI/UserProfile/RecipeFolder.vue' /* webpackChunkName: "components/u-i-user-profile-recipe-folder" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileDetails = () => import('../../components/UI/UserProfile/UserProfileDetails.vue' /* webpackChunkName: "components/u-i-user-profile-details" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeInformationLine = () => import('../../components/UI/MainBody/Recipe/InformationLine.vue' /* webpackChunkName: "components/u-i-main-body-recipe-information-line" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipePlusMinusButtons = () => import('../../components/UI/MainBody/Recipe/PlusMinusButtons.vue' /* webpackChunkName: "components/u-i-main-body-recipe-plus-minus-buttons" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeCookingInstructionRow = () => import('../../components/UI/MainBody/Recipe/RecipeCookingInstructionRow.vue' /* webpackChunkName: "components/u-i-main-body-recipe-cooking-instruction-row" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeCookingInstructions = () => import('../../components/UI/MainBody/Recipe/RecipeCookingInstructions.vue' /* webpackChunkName: "components/u-i-main-body-recipe-cooking-instructions" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeHeader = () => import('../../components/UI/MainBody/Recipe/RecipeHeader.vue' /* webpackChunkName: "components/u-i-main-body-recipe-header" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeHeaderTitle = () => import('../../components/UI/MainBody/Recipe/RecipeHeaderTitle.vue' /* webpackChunkName: "components/u-i-main-body-recipe-header-title" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeInfo = () => import('../../components/UI/MainBody/Recipe/RecipeInfo.vue' /* webpackChunkName: "components/u-i-main-body-recipe-info" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeIngredients = () => import('../../components/UI/MainBody/Recipe/RecipeIngredients.vue' /* webpackChunkName: "components/u-i-main-body-recipe-ingredients" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeListing = () => import('../../components/UI/MainBody/Recipe/RecipeListing.vue' /* webpackChunkName: "components/u-i-main-body-recipe-listing" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeNutrients = () => import('../../components/UI/MainBody/Recipe/RecipeNutrients.vue' /* webpackChunkName: "components/u-i-main-body-recipe-nutrients" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipePaywall = () => import('../../components/UI/MainBody/Recipe/RecipePaywall.vue' /* webpackChunkName: "components/u-i-main-body-recipe-paywall" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipePaywallSubscription = () => import('../../components/UI/MainBody/Recipe/RecipePaywallSubscription.vue' /* webpackChunkName: "components/u-i-main-body-recipe-paywall-subscription" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeShare = () => import('../../components/UI/MainBody/Recipe/RecipeShare.vue' /* webpackChunkName: "components/u-i-main-body-recipe-share" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeSimilar = () => import('../../components/UI/MainBody/Recipe/RecipeSimilar.vue' /* webpackChunkName: "components/u-i-main-body-recipe-similar" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeTags = () => import('../../components/UI/MainBody/Recipe/RecipeTags.vue' /* webpackChunkName: "components/u-i-main-body-recipe-tags" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodyRecipeRecipesList = () => import('../../components/UI/MainBody/Recipe/RecipesList.vue' /* webpackChunkName: "components/u-i-main-body-recipe-recipes-list" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodySearchComponentsRecipeFront = () => import('../../components/UI/MainBody/SearchComponents/RecipeFront.vue' /* webpackChunkName: "components/u-i-main-body-search-components-recipe-front" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodySearchListResults = () => import('../../components/UI/MainBody/SearchComponents/SearchListResults.vue' /* webpackChunkName: "components/u-i-main-body-search-list-results" */).then(c => wrapFunctional(c.default || c))
export const UIMainBodySearchTags = () => import('../../components/UI/MainBody/SearchComponents/SearchTags.vue' /* webpackChunkName: "components/u-i-main-body-search-tags" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileFiltersButtonFilter = () => import('../../components/UI/UserProfile/Filters/ButtonFilter.vue' /* webpackChunkName: "components/u-i-user-profile-filters-button-filter" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileFiltersCheckboxFilter = () => import('../../components/UI/UserProfile/Filters/CheckboxFilter.vue' /* webpackChunkName: "components/u-i-user-profile-filters-checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedAnimatedCircle = () => import('../../components/UI/UserProfile/shared/AnimatedCircle.vue' /* webpackChunkName: "components/u-i-user-profile-shared-animated-circle" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedCheckBox = () => import('../../components/UI/UserProfile/shared/CheckBox.vue' /* webpackChunkName: "components/u-i-user-profile-shared-check-box" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedDivider = () => import('../../components/UI/UserProfile/shared/Divider.vue' /* webpackChunkName: "components/u-i-user-profile-shared-divider" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedIcon = () => import('../../components/UI/UserProfile/shared/Icon.vue' /* webpackChunkName: "components/u-i-user-profile-shared-icon" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedIconWrapper = () => import('../../components/UI/UserProfile/shared/IconWrapper.vue' /* webpackChunkName: "components/u-i-user-profile-shared-icon-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedLoadMoreLess = () => import('../../components/UI/UserProfile/shared/LoadMoreLess.vue' /* webpackChunkName: "components/u-i-user-profile-shared-load-more-less" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedLoader = () => import('../../components/UI/UserProfile/shared/Loader.vue' /* webpackChunkName: "components/u-i-user-profile-shared-loader" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedParameterCircle = () => import('../../components/UI/UserProfile/shared/ParameterCircle.vue' /* webpackChunkName: "components/u-i-user-profile-shared-parameter-circle" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedRecipeButton = () => import('../../components/UI/UserProfile/shared/RecipeButton.vue' /* webpackChunkName: "components/u-i-user-profile-shared-recipe-button" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileHeader = () => import('../../components/UI/UserProfile/shared/UserProfileHeader.vue' /* webpackChunkName: "components/u-i-user-profile-header" */).then(c => wrapFunctional(c.default || c))
export const UIUserProfileSharedViewSwitcher = () => import('../../components/UI/UserProfile/shared/ViewSwitcher.vue' /* webpackChunkName: "components/u-i-user-profile-shared-view-switcher" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
