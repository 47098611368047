
import Vue from 'vue';
import RecipeButton from '~/components/UI/UserProfile/shared/RecipeButton.vue';
import getSubscription from '~/core/utils/getSubscription';
import PianoCustomerStateHandler from '@piano/components/PianoCustomerStateHandler.vue';

interface Methods {
  getSubscription: typeof getSubscription;
  trackEvent: (type: 'click' | 'view', title?: string) => void;
}

export default Vue.extend<unknown, Methods, unknown, unknown>({
  components: {
    RecipeButton,
    PianoCustomerStateHandler,
  },
  methods: {
    getSubscription,
    trackEvent(type, title = '') {
      let params = {};

      switch (type) {
        case 'click':
          params = {
            eventName: 'link_click',
            eventData: { BlockName: 'Recipe-web Paywall', BlockType: 'Paywall', LinkTitle: title },
          };
          break;
        case 'view':
          params = {
            eventName: 'block_view',
            eventData: { BlockName: 'Recipe-web Paywall', BlockType: 'Paywall' },
          };
          break;
      }

      this.$store.commit('analytics/setClickEvent', {
        cXense: params,
      });
    },
  },
});
