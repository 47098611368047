
import Vue from 'vue';
import { RecipeBasket, RecipeBlock, RecipeIngredient } from '~/types';
import RecipeListing from '../Recipe/RecipeListing.vue';

interface Props {
  isTotal: boolean;
  savedResults: RecipeBasket[];
}

interface Computed {
  total: RecipeBasket | null;
}

interface Methods {
  close: () => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    RecipeListing,
  },
  props: {
    isTotal: Boolean,
    savedResults: Array,
  },
  computed: {
    total() {
      if (this.savedResults.length === 0) {
        return null;
      }
      const ingredients = this.savedResults
        .reduce(
          (acc: RecipeIngredient[], recipe: RecipeBasket) => [
            ...acc,
            ...new Array(recipe.portions).fill(
              recipe?.recipe?.blocks?.reduce((acc: RecipeIngredient[], block: RecipeBlock) => [...acc, ...block.ingredients], [])
            ),
          ],
          []
        )
        .reduce((accu: RecipeIngredient[], arr) => accu.concat(arr), [])
        .reduce((accu: RecipeIngredient[], ingredient: RecipeIngredient) => {
          const itemTitle = ingredient.ingredient.title;
          const itemQuantity = ingredient.quantity;
          const measurementTitle = ingredient?.measurement?.title || '';
          const indexItemInList = accu.findIndex(({ ingredient }: RecipeIngredient) => ingredient.title === itemTitle);
          indexItemInList !== -1
            ? (accu[indexItemInList].quantity += itemQuantity)
            : accu.push({ ingredient: { title: itemTitle }, quantity: itemQuantity, measurement: { title: measurementTitle } });
          return accu;
        }, []);
      return {
        id: 'total',
        portions: 1,
        recipe: { title: `Koondnimekiri (${this.savedResults.length} retsepti)`, ingredients },
      } as RecipeBasket;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
