import { ModuleTree } from 'vuex';
import { adFormStoreModule } from '@ads/store/adForm.store';
import { pianoStoreModule } from '@piano/store/piano.store';

const indexModule = require.context('~/core/store/', true, /^(.*(?:store)).*\.ts$/);
const indexFilename = indexModule.keys().find((filename) => filename.includes('./index.'));

const storeModules = require.context('~/core/modules/', true, /^(.*(?:store)).*\.ts$/);
const storeModulesFiles = storeModules.keys();

const modules: ModuleTree<unknown> = {};
if (indexFilename) {
  modules['index'] = {
    ...indexModule(indexFilename),
  };
}

// Get store modules and import them.
for (const fileName of storeModulesFiles) {
  const moduleName: string = fileName.replace(/.*\/|\..*$/g, '');
  // If namespace is used then throw error.
  if (moduleName in modules) {
    throw new Error(`Namespace ${moduleName} already used in store.`);
  }

  modules[moduleName] = {
    namespaced: true,
    ...storeModules(fileName),
  };
}

const createModules = () => ({ ...modules, ...(pianoStoreModule as ModuleTree<unknown>), ...(adFormStoreModule as ModuleTree<unknown>) });
export default createModules;
