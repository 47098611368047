export default {
  openRecipesPeriod: {
    start: 20211213,
    end: 20211219,
  },
  fullPackageUrl: 'https://www.delfi.ee/leht/paketid',
  omamaitseId: 72,
  meta: {
    title: 'Retseptiveeb',
    description:
      'Otsid isuäratavat retsepti või inspiratsiooni, mida kodus süüa teha? Retseptiveebi otsing ja filtrid annavad hulganisti võimalusi leida just Sinu maitsele sobivad retseptid, mida saad oma kaustadesse salvestada ja nendele märkmeid lisada. Poodi minemiseks saad lisatud retseptidest ostunimekirja kaasa võtta.',
    metaImage: '/retsept-og.jpg',
    metaUrl: 'https://retseptiveeb.delfi.ee',
  },
  teaserBannerMid: 545131,
};
