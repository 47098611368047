
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RecipeIngredient, RecipeBasket, RecipeBlock } from '~/types';
//import RecipeButton from '../../UserProfile/shared/RecipeButton.vue';

interface Data {
  copied: boolean;
}

interface Props {
  recipe: RecipeBasket;
  portions: number;
  isTotal: boolean;
}

interface Methods {
  goToRecipe: (externalId: number, slug: string) => void;
  removeFromSavedResults: (id: string) => void;
  sendMail: () => void;
  copy: () => void;
  getObjectToReturn: (elementBetween: string, wantedParams: string[]) => string;
  removeBrackets: (val: string) => string;
  objectToReturn: (wantedParams: string[]) => Record<string, any>;
  clickEvent: (type: string) => void;
}

interface Computed {
  title: string;
  ingredients: RecipeIngredient[];
  theRecipe: RecipeBasket;
  copyText: string;
  savedResults: RecipeBasket[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    //RecipeButton,
  },
  props: {
    recipe: Object,
    portions: Number,
    isTotal: Boolean,
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    ...mapGetters(['savedResults']),
    theRecipe() {
      return this.recipe || null;
    },
    ingredients() {
      if (this.theRecipe?.id === 'total') {
        return this.theRecipe?.recipe?.ingredients;
      }
      if (this.theRecipe?.recipe?.blocks) {
        return this.theRecipe?.recipe.blocks?.reduce((acc: RecipeIngredient[], block: RecipeBlock) => [...acc, ...block.ingredients], []);
      }
      return [];
    },
    title() {
      return this.theRecipe && this.theRecipe?.recipe?.title + (this.portions > 1 ? ` x${this.portions}` : '');
    },
    copyText() {
      return this.getObjectToReturn('\n', ['title', 'ingredients']);
    },
  },
  methods: {
    objectToReturn(wantedParams: string[]) {
      type FullRecipe = {
        [key: string]: Record<string, any>;
      };
      type FullRecipeKey = keyof FullRecipe;
      const fullRecipeObject: FullRecipe = {
        title: { prop: 'Title: ', body: this.theRecipe?.recipe?.title },
        author: { prop: 'Autor: ', body: this.theRecipe?.recipe?.author },
        //portions: { prop: 'Portions: ', body: this.theRecipe.portions },
        ingredients: { prop: 'Koostisosad: ', body: this.ingredients },
        //calories: { prop: 'Calories: ', body: this.theRecipe.calories },
        //preparationTime: { prop: 'Preparation time: ', body: this.theRecipe.preparationTime + ' min' },
        //preparationText: { prop: 'Preparation: ', body: this.theRecipe.preparationText },
      };
      return Object.keys(fullRecipeObject).reduce(
        (acc: FullRecipe, key: FullRecipeKey) => (wantedParams.includes(String(key)) ? { ...acc, [key]: fullRecipeObject[key] } : acc),
        {}
      );
    },
    removeBrackets(text: string) {
      return text.replace(/\(([^)]+)\)/, '');
    },
    goToRecipe(externalId, slug) {
      this.$emit('close');
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push(`/retsept/${externalId}/${slug}`, () => {});
    },
    removeFromSavedResults(id) {
      const newSavedResults = this.isTotal ? [] : [...this.savedResults].filter((r: RecipeBasket) => r.id !== id);
      this.$store.commit('removeFromSavedResults', newSavedResults);
    },
    getObjectToReturn(elementBetween: string, wantedParams = []) {
      return Object.values(this.objectToReturn(wantedParams)).reduce((acc: string, item: Record<string, any>) => {
        let str = '';
        if (Array.isArray(item.body)) {
          str += item.prop + elementBetween;
          item.body.forEach((ingredient: RecipeIngredient) => {
            const ingredientTitle = this.removeBrackets(ingredient.ingredient?.title);
            const measurementTitle = ingredient.measurement?.title;
            const quantity = ingredient.quantity;
            str += '  ' + (quantity ? quantity + measurementTitle! + ' ' : '') + ingredientTitle + elementBetween;
          });
        } else if (item.body) {
          str += (item.prop === 'Title: ' ? '' : item.prop + ' ') + item.body + elementBetween;
        }
        return acc.concat(str);
      }, '');
    },
    sendMail() {
      this.clickEvent('email');
      const body = this.getObjectToReturn('%0A', ['title', 'ingredients']);
      location.href = `mailto:?body=${body}`;
    },
    clickEvent(type: string) {
      const eventData: Record<string, unknown> = {
        share_channel: type,
        total_recipes: this.savedResults.length,
        shoppinglist_name: this.title.toLowerCase(),
        recipe_name: this.recipe.recipe.title.toLowerCase(),
      };

      if (this.recipe.recipe.id) {
        eventData.recipe_id = this.recipe.recipe.id;
      }

      if (this.recipe.recipe.author) {
        eventData.recipe_author = this.recipe.recipe.author.toLowerCase();
      }
      this.$store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'recipe_shoppinglist_share',
          eventData,
        },
      });
    },
    async copy() {
      this.clickEvent('copy');
      try {
        await navigator.clipboard
          .writeText(this.copyText)
          .catch(() => null)
          .finally(() => {
            this.$notify({ title: 'Kopeeritud', type: 'success' });
          });
      } catch (error) {
        this.$notify({ title: String(this.$t('words.error')), type: 'error' });
      }
    },
  },
  filters: {
    isZero(value: string | number, quantity: number) {
      if (quantity === 0) return ' ';
      return value;
    },
  },
});
