
import Vue from 'vue';

interface Props {
  name: string;
}
export default Vue.extend<Props>({
  functional: true,
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
