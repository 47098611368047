
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CustomerDataObject, Recipe } from '~/types';
import RecipeButton from '../UserProfile/shared/RecipeButton.vue';
import ModalHeader from './ModalHeader.vue';

interface Computed {
  title: string;
  customerFolders: CustomerDataObject[];
  folders: CustomerDataObject[];
  buttonTitle: string;
  folderRecipes: Record<string, string>;
  searchResults: Recipe[];
}
interface Props {
  name: string | null;
  placeholder: string | null;
  action: string | null;
  id: string;
  isMobile: boolean;
}

type FavouriteFolder = {
  folder: string;
  folderName: string;
  status: FolderStatus;
};

interface Data {
  hover: string | null;
  favouriteFolders: FavouriteFolder[];
}

enum FolderStatus {
  TO_ADD = 'TO_ADD',
  ADDED = 'ADDED',
  TO_DELETE = 'TO_DELETE',
  UNKNOWN = 'UNKNOWN',
}

interface Methods {
  save: () => void;
  isInFolder: (folderId: string) => boolean;
  modalAction: () => void;
  addEditFolders: (folder: string, folderName: string) => void;
  isToDelete: (id: string) => boolean;
  isTwoRowFolderTitleMobile: (title: string) => boolean;
  getFolderStatus: (id: string) => FolderStatus;
  dynamicFolderIcon: (id: string) => string;
  showActive: (id: string) => boolean;
  recipeData: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    RecipeButton,
    ModalHeader,
  },
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    action: {
      type: String,
    },
    id: {
      type: String,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: null,
      favouriteFolders: [],
    };
  },
  created() {
    this.favouriteFolders = Object.keys(this.folderRecipes).reduce((acc: FavouriteFolder[], folder: string) => {
      if (this.folderRecipes[folder].includes(this.id)) {
        const folderData = this.customerFolders.find((f) => folder === `${f.key}.${f.id}`);
        folderData?.value && folderData.id && acc.push({ folderName: folderData.value, folder: folderData.id, status: FolderStatus.ADDED });
      }
      return acc;
    }, []);
  },

  computed: {
    ...mapGetters({ folders: 'customerFolders', folderRecipes: 'folderRecipes', searchResults: 'searchResults' }),
    customerFolders() {
      const folders = [...this.folders].sort(
        (a: CustomerDataObject, b: CustomerDataObject) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      return folders;
    },
    title() {
      return 'Halda kaustu:';
    },
    buttonTitle() {
      return 'lisa kaust';
    },
  },
  methods: {
    showActive(id) {
      return this.hover === id || (this.isInFolder(id) && this.getFolderStatus(id) !== FolderStatus.TO_DELETE);
    },
    dynamicFolderIcon(id) {
      let name = '';
      let folderStatus = this.getFolderStatus(id);
      const isHovered = this.hover === id;
      if (folderStatus) {
        switch (folderStatus) {
          case FolderStatus.TO_ADD:
            name = 'folder-active';
            break;
          case FolderStatus.ADDED:
            name = 'folder-active';
            break;
          case FolderStatus.TO_DELETE:
            name = '';
            break;
          default:
            break;
        }
      }
      if (isHovered) {
        switch (folderStatus) {
          case FolderStatus.TO_ADD:
            folderStatus = FolderStatus.TO_DELETE;
            name = 'folder-delete';
            break;
          case FolderStatus.TO_DELETE:
            folderStatus = FolderStatus.ADDED;
            name = 'folder-add';
            break;
          case FolderStatus.ADDED:
            folderStatus = FolderStatus.TO_DELETE;
            name = 'folder-delete';
            break;
          case FolderStatus.UNKNOWN:
            folderStatus = FolderStatus.TO_ADD;
            name = 'folder-add';
            break;
          default:
            break;
        }
      }
      return name;
    },
    isTwoRowFolderTitleMobile(title) {
      return !this.isMobile ? title.length > 17 : title.length > 13;
    },
    modalAction() {
      this.$store.commit('popModalsHistory');
      this.$store.commit('toggleModal', { modal: 'center', centerType: null, id: null });
    },
    addEditFolders(folder, folderName) {
      if (this.isInFolder(folder)) {
        const folderIndex = this.favouriteFolders?.findIndex((f: FavouriteFolder) => f?.folder === folder);
        const folderStatus = this.favouriteFolders[folderIndex].status;
        if (folderStatus === FolderStatus.ADDED) this.favouriteFolders[folderIndex].status = FolderStatus.TO_DELETE;
        else if (folderStatus === FolderStatus.TO_DELETE) this.favouriteFolders[folderIndex].status = FolderStatus.ADDED;
        else this.favouriteFolders.splice(folderIndex, 1);
      } else {
        this.favouriteFolders = [...this.favouriteFolders, { folderName, folder, status: FolderStatus.TO_ADD }];
      }
    },
    async save() {
      try {
        this.favouriteFolders.forEach(async (folder: FavouriteFolder) => {
          if (folder.status === FolderStatus.TO_ADD) {
            await this.$store.dispatch('addCustomerData', { key: `recipe-web.folder.${folder.folder}`, value: this.id });
            this.$notify({ title: folder.folderName, type: 'favourite' });
            const recipe = this.$store.getters['activeRecipe'] || this.recipeData();

            if (recipe) {
              this.$store.commit('analytics/setClickEvent', {
                cXense: {
                  eventName: 'recipe_share',
                  eventData: {
                    share_channel: 'Myrecipes',
                    recipes_folder: folder.folderName,
                    recipe_id: recipe.id,
                    recipe_name: recipe.title.toLowerCase(),
                    recipe_author: recipe.author.toLowerCase(),
                  },
                },
              });
            }
          }
          if (folder.status === FolderStatus.TO_DELETE) {
            await this.$store.dispatch('deleteCustomerData', { key: `recipe-web.folder.${folder.folder}`, value: this.id });
            this.$notify({ title: folder.folderName, type: 'unfavourite' });
          }
        });
      } catch (error) {
        this.$notify({ title: String(this.$t('words.error')), type: 'error' });
      } finally {
        this.modalAction();
      }
    },
    recipeData() {
      return this.searchResults.filter((recipe) => recipe.id === this.id)[0] || null;
    },
    isInFolder(id) {
      return this.favouriteFolders.findIndex((f) => f.folder === id) !== -1;
    },
    getFolderStatus(id) {
      return this.favouriteFolders.find((f) => f.folder === id)?.status || FolderStatus.UNKNOWN;
    },
    isToDelete(id) {
      return this.favouriteFolders.find((f) => f.folder === id)?.status === FolderStatus.TO_DELETE;
    },
  },
});
