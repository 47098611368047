
import Vue from 'vue';
import { CustomerDataObject } from '~/types';
import PianoCustomerStateHandler from '../../../libraries/piano/src/components/PianoCustomerStateHandler.vue';
import { mapGetters } from 'vuex';

interface Data {
  hover: boolean;
}

interface Props {
  active: boolean;
  data: CustomerDataObject;
  isError: boolean;
  isMobile: boolean;
}

interface Computed {
  hasSlot: boolean;
  token: boolean;
}

interface Methods {
  setActive: (folderId: string) => void;
  toggleModal: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    PianoCustomerStateHandler,
  },
  props: {
    active: Boolean,
    data: {
      type: Object,
    },
    isError: {
      type: Boolean,
    },
    isMobile: Boolean,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters(['token']),
    hasSlot() {
      return !!this.$slots.default;
    },
  },
  methods: {
    setActive(id) {
      this.$route.path !== '/' && this.$router.push('/');
      this.$store.commit('setActiveUserFolder', id);
    },
    toggleModal() {
      !this.isError && this.$emit('toggle-modal', 'kaust', 'add', null);
    },
  },
});
