export default <T extends Record<string, any>>(arr: Array<T>, paramToFilter: string): T[] => {
  const filtered: Array<T> = [];
  const dublicates: Record<string, boolean> = {};
  Array.isArray(arr) &&
    arr.forEach((r: T) => {
      if (!dublicates[r[paramToFilter]]) {
        dublicates[r[paramToFilter]] = true;
        filtered.push(r);
      }
    });
  return filtered;
};
