import { PureQueryOptions } from '@apollo/client/core';
import fetchData from '../../apollo/resolvers/fetchData';
import { GetCustomerData } from '../../apollo/typings/getCustomerData';
import query from '../../apollo/query/getCustomerData.graphql';
import { ApiClient, GetCustomerDataInput } from '~/types';

const error = {
  message: 'customer data not found.',
  statusCode: 404,
};

const getCustomerData = async (client: ApiClient, variables?: GetCustomerDataInput) => {
  const queryOptions = { variables };
  const options: PureQueryOptions = Object.assign({ query, cacheData: false }, queryOptions);
  try {
    const customerData = await fetchData<GetCustomerData['data']>(client, options);

    if (!customerData) {
      throw 'getCustomerData request is empty';
    }
    return customerData.data;
  } catch (e) {
    throw error;
  }
};

export default getCustomerData;
