import { Middleware, Context } from '@nuxt/types';
import fetchRecipeIdAndSlug from '../core/modules/services/fetchRecipeIdAndSlug';
import resolveIdType from '../core/utils/resolveIdType';

const redirectTo = async (pathType: string, context: Context) => {
  let url;
  const { query, route } = context;

  let recipe;
  let shortId;
  let slug;

  if (pathType == 'recipe' && route.params?.id?.length > 0) {
    const idType = resolveIdType(route.params.id);

    recipe = await fetchRecipeIdAndSlug(Object.assign(idType));

    if (!recipe?.data?.items?.length) {
      return 'error';
    }

    if (route.params.slug && route.params.id) {
      return;
    }

    slug = recipe.data.items[0].slug;
    shortId = recipe.data.items[0].externalId;
    url = `${shortId}/${slug}`;
    // build query strPing
    const queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');

    if (queryString.length > 0 && url.length > 0) {
      url = `${url}${!url.includes('?') ? '?' : '&'}${queryString}`;
    }

    // Add route hash to the end of path
    url = url.concat(route.hash);

    if (process.client && window.history) {
      window.history.replaceState({}, document.title, document.location.href);
    }

    return url;
  } else {
    return;
  }
};

const pathHandler: Middleware = async (context) => {
  const { route, redirect, app } = context;

  if (process.client) {
    return;
  }

  const redirectPath = route.name && (await redirectTo(route.name, context));

  if (redirectPath && redirectPath !== 'error') {
    try {
      redirect(301, redirectPath);
    } catch (e: any) {
      app.$sentry.captureException(`Redirect failed: ${e.message}, original url: ${route.fullPath}, redirect path: ${redirectPath}`, {
        tags: { 'process.server': process.server ? 'server' : 'client' },
      });
      redirect(301, encodeURI(redirectPath));
    }
  } else if (redirectPath === 'error') {
    if (route.matched.length) {
      context.error({
        message: '',
      });
      return;
    }
  }
};

export default pathHandler;
