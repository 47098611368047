import { ServiceConfig } from '~/core/modules/service-config/typings/ServiceConfig';

const defaultServiceConfig: ServiceConfig = {
  piano: {
    maintenanceMode: false,
    loginApiClientSrc: 'https://ee-test-login-api-client.s3.delfi.net/test_ee.js',
    account: {
      aid: 'K9tVlFkvsu',
      scriptSrc: 'https://sandbox.tinypass.com/xbuilder/experience/load?aid=K9tVlFkvsu',
      ESPId: '983',
    },
    accessResources: {
      channelAccess: ['R9XPTZI', 'R8JSBXL'],
      adFree: ['R86CBKW'],
    },
    offers: {
      paywall: {
        offerId: 'OFYCXR51P394',
        templateId: 'OTJE3N8J1BY6',
        displayMode: 'inline',
        showCloseButton: false,
        checkoutFlowId: 'CFIV3AGGIFE0',
        promoCode: 'PAYWALL',
        templateVariantId: 'OTV8MJ3J1NKCK',
      },
    },
  },
};

export { defaultServiceConfig };
