
import Vue from 'vue';
interface Data {
  title: string;
}

interface Methods {
  redirect: () => void;
}

export default Vue.extend<Data, Methods, unknown, unknown>({
  data() {
    return {
      title: 'Oma Maitse',
    };
  },
  methods: {
    redirect() {
      window?.open('https://omamaitse.delfi.ee/', '_blank');
    },
  },
});
