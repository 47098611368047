
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      mounted: false,
    };
  },
  mounted() {
    this.mounted = true;
  },
});
