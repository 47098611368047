import { Store } from 'vuex';
import { Tag } from '~/types';
import { RootState } from './store-types';

export const onFilterChange = (store: Store<RootState>) => {
  if (process.server) {
    return;
  }
  store.subscribe((mutation) => {
    const { type, payload } = mutation;

    if (type == 'setSelectedTags' && payload !== null && payload !== '') {
      const { currentRoute } = store?.$router;
      const searchTags = store.getters.searchTags.reduce((acc: string[], tag: Tag) => [...acc, tag.id], []);
      const query = { ...currentRoute.query, tags: searchTags };
      const pathTags = currentRoute.query.tags
        ? typeof currentRoute.query.tags === 'string'
          ? currentRoute.query.tags
          : currentRoute.query.tags.join('')
        : '';

      if (pathTags !== searchTags.join('')) {
        store.$router.replace({ query, path: '/' });
      }
    }
    if (type == 'setSearchTerm' && payload !== null) {
      const { currentRoute } = store?.$router;
      const currentQuery = currentRoute?.query || {};
      const query = { ...currentQuery };

      if (payload) {
        query.search = payload;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        store.$router.push({ query }, () => {});
      } else {
        delete query.search;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        store.$router.replace({ query }, () => {});
      }
    }
  });
};
