import updateData from '../../apollo/resolvers/updateData';
import { UpdateCustomerData } from '../../apollo/typings/updateCustomerData';
import mutation from '../../apollo/query/updateCustomerData.graphql';
import { ApiClient, MutationOptions } from '~/types';

const error = {
  message: 'create data not found.',
  statusCode: 404,
};

const updateCustomerData = async (client: ApiClient, variables?: UpdateCustomerData['variables']) => {
  const queryOptions = { variables };
  const options: MutationOptions = Object.assign({ mutation, cacheData: false }, queryOptions);

  try {
    const customerData = await updateData<UpdateCustomerData['data']>(client, options);

    if (!customerData) {
      throw 'updateCustomerData channels request is empty';
    }
    return customerData;
  } catch (e) {
    throw error;
  }
};

export default updateCustomerData;
