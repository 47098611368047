import { PureQueryOptions, FetchPolicy, ErrorPolicy } from '@apollo/client/core';
import { ApiClient } from '../../../types';
import apolloClient from '../apolloClient';

interface FetchData<T = any> {
  data: T;
  errors: Error[];
}

interface FetchAndErrorPolicy {
  fetchPolicy: FetchPolicy;
  errorPolicy: ErrorPolicy;
}

export interface fetchDataOptions extends PureQueryOptions {
  cacheData?: boolean;
}

const fetchData = async <T>(apiClient: ApiClient, queryOptions: fetchDataOptions) => {
  const fetchAndErrorPolicy: FetchAndErrorPolicy = {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  };
  const { ...fetchQueryOptions } = Object.assign({}, fetchAndErrorPolicy, queryOptions);

  try {
    let dataCache: FetchData<T> | undefined;

    if (dataCache) {
      return dataCache;
    }
    const { data } = await apolloClient(apiClient.client, apiClient.authToken).query<FetchData<T>>(fetchQueryOptions);

    return data;
  } catch (error: any) {
    throw {
      debug: error,
      message: error?.message,
      statusCode: error?.networkError?.statusCode,
    };
  }
};
export default fetchData;
