import { PureQueryOptions } from '@apollo/client/core';
import fetchData from '../../apollo/resolvers/fetchData';
import { GetRecipes } from '../../apollo/typings/getRecipes';
import getRecipeIdAndSlug from '../../apollo/query/getRecipeIdAndSlug.graphql';
import { ClientTypes, GetRecipesInput } from '~/types';
import RedisClient from '../../redis/redisClient';
import { FetchRecipeIdAndSlug } from '~/core/apollo/typings/fetchRecipeIdAndSlug';

interface Variables {
  id?: GetRecipesInput['id'];
  externalId?: GetRecipesInput['externalId'];
}

interface QueryOptions {
  variables?: {
    input: {
      externalId?: GetRecipesInput['externalId'];
      id?: GetRecipesInput['id'];
    };
  };
}

function getQueryOptions({ id, externalId }: Variables) {
  let queryOptions: QueryOptions = {};
  if (id) {
    queryOptions = {
      variables: {
        input: {
          id: id,
        },
      },
    };
  } else if (externalId) {
    queryOptions = {
      variables: {
        input: {
          externalId: externalId,
        },
      },
    };
  }
  return queryOptions;
}

const fetchRecipeIdAndSlug = async (variables?: Variables): Promise<FetchRecipeIdAndSlug['response'] | undefined> => {
  const queryOptions = variables && getQueryOptions(variables);
  const options: PureQueryOptions = Object.assign({ query: getRecipeIdAndSlug, cacheData: false }, queryOptions);
  try {
    // If we have a cached entry send it
    const key = `getRecipeIdAndSlug::${JSON.stringify(variables)}`;
    const dataCache = await RedisClient.get(key);

    if (dataCache) {
      return JSON.parse(dataCache);
    }

    const recipes = await fetchData<GetRecipes['data']>({ client: ClientTypes.recipes }, options);
    await RedisClient.set(key, recipes, 86400);

    return recipes;
  } catch (e) {
    return;
  }
};

export default fetchRecipeIdAndSlug;
