
import Vue from 'vue';
import { mapGetters } from 'vuex';

interface Props {
  title: string;
  action: () => void;
  showMobileTitle: boolean;
}

interface Computed {
  modalsHistory: any[];
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    title: {
      type: String,
    },
    action: Function,
    showMobileTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['modalsHistory']),
  },
});
