import Vuex from 'vuex';
import Vue from 'vue';
import createModules from './storeModulesImporter';
import { RootState } from './store-types';
import { onFilterChange } from './filter-plugin';
import { onActiveFolderChange } from './active-folder-plugin';

Vue.use(Vuex);
const modules = createModules();
const store = new Vuex.Store<RootState>({
  modules,
  plugins: [onFilterChange, onActiveFolderChange],
});

export default store;
