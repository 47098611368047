
import Vue from 'vue';

interface Props {
  title: string;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    title: String,
  },
});
