
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RecipeBasket } from '~/types';
import ViewSwitcher from '../UI/UserProfile/shared/ViewSwitcher.vue';
import RecipesList from '../UI/MainBody/Recipe/RecipesList.vue';

interface Computed {
  isTotal: boolean;
  savedResults: RecipeBasket[];
}

interface Data {
  selectedTab: number;
}

interface Methods {
  setSelected: (tab: number) => void;
  close: () => void;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    ViewSwitcher,
    RecipesList,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters(['savedResults']),
    isTotal() {
      return this.selectedTab === 1;
    },
  },
  methods: {
    setSelected(tab) {
      this.selectedTab = tab;
    },
    close() {
      this.$emit('close');
    },
  },
});
