import { Middleware } from '@nuxt/types';

const headers: Middleware = async (context) => {
  const { res, query } = context;

  if (process.client) {
    return;
  }

  if (query.preview) {
    res.setHeader('Cache-control', 'no-cache');
  } else {
    res.setHeader('Cache-control', `public, max-age=300`);
  }
};

export default headers;
