
import Vue from 'vue';

import Loader from '../components/UI/UserProfile/shared/Loader.vue';

interface Methods {
  start: () => void;
  finish: () => void;
}

interface Data {
  loading: boolean;
}

export default Vue.extend<Data, Methods, unknown, unknown>({
  data: () => ({
    loading: true,
  }),
  components: {
    Loader,
  },
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      this.loading = false;
    },
  },
});
