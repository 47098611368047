import Vue from 'vue';

import Icon from '../components/UI/UserProfile/shared/Icon.vue';
import IconWrapper from '../components/UI/UserProfile/shared/IconWrapper.vue';
import Modal from '../components/Navigation/Modal.vue';
import Divider from '../components/UI/UserProfile/shared/Divider.vue';
import BaseButton from '@core/components/UI/BaseButton.vue';

Vue.component('Icon', Icon);
Vue.component('IconWrapper', IconWrapper);
Vue.component('Modal', Modal);
Vue.component('Divider', Divider);
Vue.component('BaseButton', BaseButton);
