import { Store } from 'vuex';
import { RootState } from './store-types';

export const onActiveFolderChange = (store: Store<RootState>) => {
  if (process.server) {
    return;
  }
  store.subscribe(async (mutation) => {
    const { type, payload } = mutation;
    if (type == 'setActiveUserFolder') {
      const isSameAsActiveFolder = store.getters.activeUserFolder === payload;
      const folderFavouriteRecipes = store.getters.folderRecipes[`recipe-web.folder.${payload}`] || [];
      const variables = !isSameAsActiveFolder || !payload ? { limit: 20 } : { id: folderFavouriteRecipes };
      await store.dispatch('getRecipesWithSearch', variables);
    }
  });
};
