import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from, DefaultOptions } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import ApolloLinkTimeout from 'apollo-link-timeout';
import api from '../config/api.config';
import fetch from 'isomorphic-fetch';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { ClientTypes } from '../../types';

const timeoutLink = new ApolloLinkTimeout(process.server ? 3000 : 10000);
const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true, sha256 });

// HTTP connection to the API
const httpLink = (uri: string, useGETForQueries: boolean) => timeoutLink.concat(new HttpLink({ uri, fetch, useGETForQueries }));

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
});

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const authMiddleware = (authToken: string | null) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : null,
      },
    }));

    return forward(operation);
  });

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

function apolloClient(client: ClientTypes, authToken: string | null = null) {
  // Create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  let apolloClient;
  switch (client) {
    case ClientTypes.recipes:
      apolloClient = createRecipeApolloClient();
      break;
    case ClientTypes.customer:
      apolloClient = createCustomerApolloClient(authToken);
      break;
    default:
      apolloClient = createRecipeApolloClient();
      break;
  }

  if (process.server) {
    return apolloClient;
  }

  // Reuse client on the client-side
  // if (!globalApolloClient) {
  //   globalApolloClient = apolloClient;
  // }
  globalApolloClient = apolloClient;

  return globalApolloClient;
}

function createRecipeApolloClient() {
  return new ApolloClient({
    ssrMode: process.server,
    link: from([persistedQueryLink, httpLink(`${api.recipes}/graphql`, false)]),
    cache,
    defaultOptions,
  });
}

function createCustomerApolloClient(authToken: string | null) {
  return new ApolloClient({
    ssrMode: process.server,
    link: from([authMiddleware(authToken), httpLink(`${api.customer}/graphql`, false)]),
    cache,
    defaultOptions,
  });
}

export default apolloClient;
