import { Plugin } from '@nuxt/types';
import { getServiceConfig } from '~/core/modules/service-config/services/getServiceConfig';

const serviceConfigPlugin: Plugin = async (ctx) => {
  let serviceConfig = ctx.store.getters['serviceConfig'];

  if (serviceConfig) {
    return;
  }

  serviceConfig = await getServiceConfig();
  ctx.store.commit('serviceConfig', serviceConfig);
};

export default serviceConfigPlugin;
