export const Landing = () => import(/* webpackChunkName: "page/Landing" */ '../../pages/landing/index.vue').then((m) => m.default || m);
export const Recipes = () => import(/* webpackChunkName: "page/Recipes" */ '../../pages/index.vue').then((m) => m.default || m);
export const RecipeBase = () => import(/* webpackChunkName: "page/RecipeBase" */ '../../pages/recipes/index.vue').then((m) => m.default || m);
export const Recipe = () => import(/* webpackChunkName: "page/Recipe" */ '../../pages/recipes/_id.vue').then((m) => m.default || m);
export const Embed = () => import(/* webpackChunkName: "page/Embed" */ '../../pages/embed.vue').then((m) => m.default || m);
export const NotFound = () => import(/* webpackChunkName: "page/NotFound" */ '../../layouts/error.vue').then((m) => m.default || m);
export const routerComponents = {
  Landing,
  Recipes,
  RecipeBase,
  Recipe,
  NotFound,
  Embed,
};
