
import Vue from 'vue';

import RecipeButton from '../UserProfile/shared/RecipeButton.vue';
import ModalHeader from './ModalHeader.vue';

interface Props {
  title: string;
  name: string;
  placeholder: string;
  id: string;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    RecipeButton,
    ModalHeader,
  },
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  computed: {
    changeTitle() {
      return 'Muuda kausta nime';
    },
    deleteTitle() {
      return 'Kustuta kaust';
    },
  },
});
