
import Vue from 'vue';
import { mapGetters } from 'vuex';
import getTagGroups from '@/core/modules/services/getTagGroups';

import MyRecipes from '../UI/UserProfile/MyRecipes.vue';
import Footer from '../UI/UserProfile/Footer.vue';

import ButtonFilter from '../UI/UserProfile/Filters/ButtonFilter.vue';
import CheckboxFilter from '../UI/UserProfile/Filters/CheckboxFilter.vue';
import UserProfileDetails from '../UI/UserProfile/UserProfileDetails.vue';

import Login from '../UI/UserProfile/Login.vue';
import Logout from '../UI/UserProfile/Logout.vue';
import LogoBox from '../UI/UserProfile/LogoBox.vue';
import { Tag, TagGroup } from '~/types';

interface Computed {
  displayName: string;
  customerFolders: any[];
  tagGroups: TagGroup[];
  activeUserFolder: string | null;
  searchTags: string[];
  hasAccess: boolean;
  token: string | null;
}

interface Methods {
  toggleModal: (name: string, method: string, id: string) => void;
  toRender: (number: number) => void;
  tagGroupTitle: (item: TagGroup) => string;
  tagGroupItems: (item: TagGroup) => Tag[];
  showRecipes: () => void;
}

interface Data {
  showCheckRecipesButton: boolean;
  scrollerHeight: number;
}

interface Props {
  isMobile: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Login,
    LogoBox,
    MyRecipes,
    Footer,
    ButtonFilter,
    CheckboxFilter,
    Logout,
    UserProfileDetails,
  },
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      showCheckRecipesButton: false,
      scrollerHeight: 0,
    };
  },
  computed: {
    ...mapGetters(['tagGroups', 'customerFolders', 'activeUserFolder', 'searchTags', 'hasAccess', 'token']),
    displayName() {
      const displayName = this.$store.state.piano.extendedProfile?.displayName;
      const email = this.$store.state.piano.profile.email;
      const name = displayName || email;

      return (name?.length > 15 ? name.slice(0, 15) + '...' : name) || '';
    },
  },
  fetchOnServer: false,
  async fetch() {
    const { $route, $store } = this;
    const { query } = $route;

    try {
      if (this.tagGroups.length > 0) {
        return;
      }
      const result = await getTagGroups();

      if (!result.data) {
        return;
      }
      $store.commit('setTagGroups', result.data);

      if ('tags' in query) {
        const getterTags = $store.getters.tagGroups.reduce((acc: Tag[], tag: TagGroup) => [...acc, ...tag.tags.items], []);
        const queryTags = Array.isArray(query.tags) ? query.tags : [query.tags];
        const tags =
          (queryTags &&
            queryTags.length > 0 &&
            // @ts-ignore
            queryTags.reduce((acc: Tag[], tag: string) => {
              const foundTag = getterTags.find((t: Tag) => t.id === tag);
              if (foundTag) return [...acc, foundTag];
              return acc;
            }, [])) ||
          [];
        tags && tags.length > 0 && $store.commit('setSelectedTags', tags);
      }
    } catch (e: any) {
      this.$sentry.captureException(`getTagGroups error: ${e.message}`, { contexts: { errorData: { e } } });
      this.$store.commit('setError', true);
    }
  },
  methods: {
    showRecipes() {
      if (this.$route.name === 'recipe') {
        this.$router.push({ path: '/', query: this.$route.query });
      }
      this.$emit('close');
    },
    toggleModal(name, method, id) {
      this.$emit('toggle-modal', name, method, 'center', id);
    },
    toRender(number = 0) {
      switch (number) {
        case 4:
          return 'ButtonFilter';
        default:
          return 'CheckboxFilter';
      }
    },
    tagGroupTitle(item) {
      return item.title;
    },
    tagGroupItems(item) {
      const copyArray = [...item.tags.items];
      if (this.tagGroupTitle(item) === 'Allergeenid') {
        const allergens = [
          { original: 'munavaba', new: 'muna' },
          {
            original: 'pähklivaba',
            new: 'pähklid',
          },
          {
            original: 'mereannivaba',
            new: 'mereannid',
          },
          { original: 'piimavaba', new: 'piim' },
        ];
        return copyArray.map((item) => {
          const found = allergens.find((allergen) => allergen.original.toLowerCase() === item.title.toLowerCase());
          item.title = found ? found.new : item.title;
          return item;
        });
      }

      return copyArray;
    },
  },
});
