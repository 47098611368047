import resetUsersQuery from '@piano/apollo/graphql/resetUser.graphql';
import { handleApolloClientMutation } from '@piano/apollo/apolloClient';
import { ResetUser } from '@piano/apollo/types/ResetUser';

export default class PianoIntegratorService {
  private readonly _apiUrl: string;

  constructor(apiUrl = '') {
    this._apiUrl = apiUrl;
  }

  public async resetUser(token: string): Promise<boolean> {
    const options = {
      mutation: resetUsersQuery,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };

    try {
      const response = await handleApolloClientMutation<ResetUser['data']>(options, this._apiUrl);

      return response.data?.resetUser.status || false;
    } catch (e) {
      console.log('PianoIntegratorService error', e);
      return false;
    }
  }
}
