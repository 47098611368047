
import Vue from 'vue';
import { mapGetters } from 'vuex';
import RecipeButton from '../UserProfile/shared/RecipeButton.vue';
import deleteCustomerData from '~/core/modules/services/deleteCustomerData';
import { ClientTypes } from '~/types';
import { PianoState } from '@piano/types/pianoState';
import ModalHeader from './ModalHeader.vue';

interface Props {
  name: string;
  id: string;
}

interface Methods {
  deleteFolder: () => void;
}

interface Computed {
  customer: PianoState;
  title: string;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    RecipeButton,
    ModalHeader,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters(['customer']),
    title() {
      return `Oled kindel, et soovid kustutada kausta ${this.name} ?`;
    },
  },
  methods: {
    async deleteFolder() {
      try {
        deleteCustomerData({ client: ClientTypes.customer, authToken: this.customer.token }, { input: { id: this.id } }).then(
          (r) => r.data.status && this.$store.dispatch('refreshCustomerFolders')
        );
        this.$store.commit('analytics/setClickEvent', {
          cXense: {
            eventName: 'recipe_favfolder_change',
            eventData: { folder_action: 'delete', folder_name: this.name.toLowerCase() },
          },
        });
      } catch (error) {
        this.$emit('close');
        this.$notify({ title: String(this.$t('words.error')), type: 'error' });
      } finally {
        this.$emit('close');
        this.$notify({ title: this.name, type: 'folder-erased' });
      }
    },
  },
});
