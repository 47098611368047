export default <T extends Record<string, any>>(arrayToCopy: Array<T>, incomingData: Array<T> | T): T[] => {
  if (!Array.isArray(incomingData)) {
    return [incomingData];
  }

  let copy = [...arrayToCopy];

  incomingData.forEach((tag: T) => {
    const exists = copy.find((t: T) => t.id === tag.id);
    if (exists) {
      copy = copy.filter((t: T) => t.id !== tag.id);
    } else {
      copy.push(tag);
    }
  });
  return copy;
};
