import { Plugin } from '@nuxt/types';
import apiConfig from '~/core/config/api.config';

/*
  https://docs.piano.io/getting-started-module-2-setting-up-piano-scripts/#thepianoscript
  https://docs.piano.io/track/id-implementation
  https://docs.piano.io/callbacks/
  */
const piano: Plugin = async (ctx) => {
  const { app, route, store } = ctx;
  const { piano } = ctx.store.getters['serviceConfig'];

  const disableMaintenance = Number(route.query?.disableMaintenance) === 1;
  const maintenanceMode = Boolean(piano.maintenanceMode);

  if (!app.mixins) {
    app.mixins = [];
  }

  app.mixins.push({
    async mounted() {
      await store.dispatch('piano/init', {
        aid: piano.account.aid,
        scriptSrc: piano.account.scriptSrc,
        loginApiClientSrc: piano.loginApiClientSrc,
        locale: 'et_EE',
        accessResources: piano.accessResources,
        offersConfig: piano.offers,
        ESPId: piano.account.ESPId,
        defaultPromotions: piano.defaultPromotions,
        maintenanceMode: maintenanceMode && !disableMaintenance,
        offersConfiguration: piano.offersConfiguration,
        ipAccessApiUrl: apiConfig.ipAccessApiUrl,
      });
    },
  });

  /*
   * Whenever new content is loaded without a browser refresh, you’ll need to execute the
   * Piano experience you've designed in Composer so that each new applicable change on the
   * page or URL is made known to Composer in place of a true page load.
   * */
  app.router!.afterEach((to, from) => {
    if (from.name) {
      const routeName = to.name;
      // FIXME: Push domain instead of empty string
      store.dispatch('piano/setTags', ['', routeName]);
      store.dispatch('piano/executeExperience');
    }
  });

  store.watch(
    (state) => state.piano.scriptLoaded,
    async (scriptLoaded) => {
      if (scriptLoaded) {
        /*
         * Should be fired only once, do some actions that are needed on first load
         * */

        const routeName = app.router?.currentRoute.name;
        // FIXME: Push domain instead of empty string
        await store.dispatch('piano/setTags', ['', routeName]);
      }
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.piano.isScriptInited,
    async (isScriptInited) => {
      if (isScriptInited) {
        const hasChannelAccess = store.state.piano.access.channelAccess;
        if (hasChannelAccess) {
          await pastAuthorizationActions();
        }
      }
    },
    {
      immediate: true,
    }
  );

  const pastAuthorizationActions = async () => {
    try {
      // @ts-ignore
      const localStorageResults = localStorage?.getItem('savedRecipes');
      const savedResults = localStorageResults && JSON.parse(localStorageResults);
      if (savedResults) {
        store.commit('setSavedResults', { recipe: savedResults, setAll: true });
      }
      await store.dispatch('refreshCustomerFolders');
    } catch (error) {
      console.error('pastAuthorization error', error);
    }
  };
};

export default piano;
