
import Vue from 'vue';

interface Computed {
  animation: string;
}

interface Props {
  show: boolean;
  side: string;
  isStatic: boolean;
  isAutoHeight: boolean;
}

export default Vue.extend<unknown, Computed, unknown, Props>({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      required: true,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    isAutoHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    animation() {
      return this.side !== 'center' ? 'slide' : 'opacity';
    },
  },
});
