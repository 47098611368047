
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CustomerDataObject } from '~/types';
import Header from '../../UI/UserProfile/shared/UserProfileHeader.vue';
import LoadMoreLess from '../UserProfile/shared/LoadMoreLess.vue';
import RecipeFolder from '../UserProfile/RecipeFolder.vue';

interface Data {
  isLoaded: boolean;
  limit: number;
}

interface Props {
  folders: CustomerDataObject[];
  isMobile: boolean;
}

interface Methods {
  toggleModal: (name: string, method: string, id: string) => void;
  showMore: () => void;
  resetLimit: () => void;
}

interface Computed {
  itemsToShow: CustomerDataObject[];
  isLoadMoreButtonVisible: boolean;
  active: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Header,
    LoadMoreLess,
    RecipeFolder,
  },
  props: {
    folders: Array,
    isMobile: Boolean,
  },
  data() {
    return {
      isLoaded: false,
      limit: 4,
    };
  },
  computed: {
    ...mapGetters({ active: 'activeUserFolder', isError: 'isError' }),
    isLoadMoreButtonVisible() {
      return this.folders.length > this.limit;
    },
    itemsToShow() {
      return this.folders.slice(0, this.limit);
    },
  },
  methods: {
    toggleModal(name, method, id) {
      this.$emit('toggle-modal', name, method, id);
    },
    showMore() {
      this.limit += 4;
    },
    resetLimit() {
      this.limit = 4;
    },
  },
});
