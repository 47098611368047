
interface Computed {
  savedResults: RecipeBasket[];
  prevRoute: any;
}

interface Methods {
  clearAllFilters: () => void;
  goBack: () => void;
}

import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RecipeBasket } from '~/types';
export default Vue.extend<unknown, Methods, Computed, unknown>({
  computed: {
    ...mapGetters(['savedResults', 'prevRoute']),
  },
  methods: {
    clearAllFilters() {
      this.$store.dispatch('clearAllFilters');
    },
    goBack() {
      if (this.prevRoute && this.prevRoute !== 'login') {
        this.$router.back();
      } else this.$router.push('/');
    },
  },
});
