import deleteData from '../../apollo/resolvers/deleteData';
import { BatchDeleteCustomerData } from '../../apollo/typings/deleteCustomerDataBatch';
import mutation from '../../apollo/query/deleteCustomerDataBatch.graphql';
import { ApiClient, MutationOptions } from '~/types';

const error = {
  message: 'customer data not found.',
  statusCode: 404,
};

const batchDeleteCustomerData = async (client: ApiClient, variables?: BatchDeleteCustomerData['variables']) => {
  const queryOptions = { variables };
  const options: MutationOptions = Object.assign({ mutation, cacheData: false }, queryOptions);

  try {
    const customerData = await deleteData<BatchDeleteCustomerData['data']>(client, options);

    if (!customerData) {
      throw 'batchDeleteCustomerData channels request is empty';
    }
    return customerData;
  } catch (e) {
    throw error;
  }
};

export default batchDeleteCustomerData;
