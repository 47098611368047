import Vue from 'vue';
import Router from 'vue-router';
import { Route, NavigationGuardNext } from 'vue-router';
import routerBase from './router.base';
import store from '../store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: routerBase,
  scrollBehavior(to, from, savedPosition) {
    // Use native browser position helper
    if (from.name === 'main' && to.name === 'main' && !savedPosition && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'auto';
      return;
    }

    // savedPosition is triggered by the browser's back/forward buttons
    if (to.name === 'recipe' || to.name === 'login' || to.name === 'register') {
      // Scroll to top on page change
      return { x: 0, y: 0 };
    } else if (to.name === 'main') {
      return new Promise((resolve) => {
        const position = savedPosition || { x: 0, y: 0 };
        setTimeout(() => {
          resolve(position);
        }, 200);
      });
    } else {
      return savedPosition;
    }
  },
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  const isModalOpen = store.getters.isModalOpen;
  const isAnyModalOpen = Object.values(isModalOpen).includes(true);
  if (isAnyModalOpen) {
    if (to.name !== from.name) {
      if (isModalOpen.left) {
        next();
      } else {
        await store.commit('resetModals');
        next(false);
      }
    } else {
      next();
    }
  } else next();
});

export function createRouter() {
  return router;
}
