import { ServiceConfig } from '~/core/modules/service-config/typings/ServiceConfig';
import { defaultServiceConfig } from '~/core/modules/service-config/config/defaultServiceConfig';
import RedisClient from '~/core/redis/redisClient';
import axios from 'axios';
import { Severity } from '@sentry/types';
import apiConfig from '~/core/config/api.config';

const getServiceConfig = async (): Promise<ServiceConfig> => {
  const key = `getServiceConfigv3::${apiConfig.serviceConfig}`;

  try {
    const dataCache = await RedisClient.get(key);

    if (dataCache) {
      return JSON.parse(dataCache);
    }
  } catch (e) {
    console.log('getServiceConfig redis get failed');
    process.sentry?.captureMessage('getServiceConfig redis get failed', {
      level: Severity.Error,
      extra: {
        file: 'getServiceConfig.ts',
        method: 'getServiceConfig',
        e,
      },
    });
  }

  let data: ServiceConfig | null = null;

  try {
    const resp = await axios.get(apiConfig.serviceConfig);
    data = resp.data;
  } catch (e) {
    console.log('getServiceConfig error');
    process.sentry?.captureMessage('getServiceConfig error', {
      level: Severity.Error,
      extra: {
        file: 'getServiceConfig.ts',
        method: 'getServiceConfig',
        e,
      },
    });
  }

  if (!data) {
    return defaultServiceConfig;
  }

  try {
    await RedisClient.set(key, data, 60 * 2);
  } catch (e) {
    console.log('getServiceConfig redis set failed');
    process.sentry?.captureMessage('getServiceConfig redis set failed', {
      level: Severity.Error,
      extra: {
        file: 'getServiceConfig.ts',
        method: 'getServiceConfig',
        e,
      },
    });
  }

  return data;
};

export { getServiceConfig };
